<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title" v-if="$route.params.id">Editar disponibilidade</h3>
          <h3 class="card-title" v-else>Nova disponibilidade</h3>
        </div>
        <center v-if="isLoading">Carregando...</center>
        <form @submit.prevent="save" v-else>
          <div class="card-body">
            <form-input label="Nome" v-model="disponibility.name" :required="true" :autofocus="true" />
            <form-input
              label="Quantidade de dias úteis para entrega"
              v-model="disponibility.minimum_days"
              type="number"
              min="0"
              :required="true"
              helpText="Dependendo da disponibilidade dos produtos, considerando a data atual mais os dias informado acima que será liberado no calendário do cliente."
            />
          </div>
          <div class="card-footer text-right">
            <div class="d-flex">
              <router-link class="btn btn-link" :to="{ name: 'product-disponibilities' }">Cancelar</router-link>
              <form-save-btn class="ml-auto" label="Salvar" :isLoading="isSaving" />
            </div>
          </div>
        </form>
      </div>
      <small>* campos obrigatórios</small>
    </div>
  </div>
</template>

<script>
import formInput from "@/components/form-input";
import formSaveBtn from "@/components/form-save-btn";
import productDisponibilityClient from "@/clients/productDisponibility";

export default {
  components: {
    formInput,
    formSaveBtn,
  },
  data: function () {
    return {
      isLoading: true,
      isSaving: false,
      disponibility: {
        name: "",
        minimum_days: 0,
      },
    };
  },
  methods: {
    async save() {
      this.isSaving = true;
      if (this.$route.params.id) {
        await productDisponibilityClient.update(
          this.$route.params.id,
          this.disponibility
        );
      } else {
        await productDisponibilityClient.create(this.disponibility);
      }
      this.$toast.success("Disponibilidade salva.");
      this.$router.push({ name: "product-disponibilities" });
    },
    async getData() {
      if (this.$route.params.id) {
        this.disponibility = await productDisponibilityClient.getOne(
          this.$route.params.id
        );
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
</style>
