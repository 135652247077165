import authService from "@/services/auth";
import Router from '@/router';

export default {
  async _handleResult(result) {

    if (result.status === 401 || result.status === 403) {
      authService.remove();
      Router.push({
        name: "login"
      });
    }

    if (result.status === 400) {
      const response = await result.json();
      if (response && response.message) {
        throw Error(response.message);
      }
    }

    if (!result.ok) {
      throw Error(result.statusText);
    }

    if (result.status === 204) {
      return null;
    } else {
      return result.json();
    }
  },
  async _request(path, method, query = null, data = null, options = {}) {

    const headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${authService.getToken()}`,
    };

    if (!options.upload) {
      headers['Content-Type'] = 'application/json';
    }

    const result = await fetch(`${process.env.VUE_APP_ROOT_API}/${path}${query ? this.getQueryParams(query) : ''}`, {
      method,
      headers,
      body: data ? options.upload ? data : JSON.stringify(data) : undefined
    });

    return this._handleResult(result);
  },
  getQueryParams(obj) {
    if (!obj) return '';
    const params = new URLSearchParams();
    for (let [key, value] of Object.entries(obj)) {
      params.append(key, value);
    }

    return '?' + params.toString();
  },
  get(path, query) {
    return this._request(path, 'GET', query);
  },
  post(path, data) {
    return this._request(path, 'POST', null, data);
  },
  put(path, data) {
    return this._request(path, 'PUT', null, data);
  },
  delete(path) {
    return this._request(path, 'DELETE');
  },
  upload(path, data) {
    return this._request(path, 'POST', null, data, {
      upload: true
    });
  }
};