import Index from '@/routes/users';
import Form from '@/routes/users/form';

export default [{
  path: '/users',
  name: 'users',
  component: Index
}, {
  path: '/users/new',
  name: 'users-new',
  component: Form
}, {
  path: '/users/:id',
  name: 'users-edit',
  component: Form
}];