import Index from '@/routes/product-disponibilities';
import Form from '@/routes/product-disponibilities/form';
import Automation from '@/routes/product-disponibilities/automation';

export default [{
  path: '/product-disponibilities',
  name: 'product-disponibilities',
  component: Index
}, {
  path: '/product-disponibilities/new',
  name: 'product-disponibilities-new',
  component: Form
}, {
  path: '/product-disponibilities/automation',
  name: 'product-disponibilities-automation',
  component: Automation
}, {
  path: '/product-disponibilities/:id',
  name: 'product-disponibilities-edit',
  component: Form
}];