<template>
  <b-modal :id="id" title="Editar desconto e acréscimo" header-close-content @show="reset">
    <form-money-input ref="discount" label="Desconto" v-model="discount" />
    <form-money-input label="Acréscimo" v-model="addition" />
    <template v-slot:modal-footer="{ cancel }">
      <button
        type="button"
        class="btn btn-secondary"
        :disabled="isLoading"
        @click="cancel()"
      >Cancelar</button>
      <button
        type="button"
        class="btn btn-primary"
        :class="{'btn-loading': isLoading}"
        @click="save()"
      >Salvar</button>
    </template>
  </b-modal>
</template>

<script>
import formMoneyInput from "@/components/form-money-input";
import orderClient from "@/clients/order";
import { VBModal } from "bootstrap-vue";

export default {
  name: "order-discount-addition-modal",
  props: ["order"],
  directives: {
    "b-modal": VBModal
  },
  components: {
    formMoneyInput
  },
  data: function() {
    return {
      isLoading: false,
      addition: 0,
      discount: 0
    };
  },
  computed: {
    id() {
      return `order-discount-addition-modal`;
    }
  },
  methods: {
    open() {
      this.$bvModal.show(this.id);
    },
    close() {
      this.$bvModal.hide(this.id);
    },
    reset() {
      this.isLoading = false;
      this.addition = this.order.addition;
      this.discount = this.order.discount;
    },
    async save() {
      this.isLoading = true;
      // TODO: handle error
      await orderClient.update(this.order.id, {
        addition: this.addition,
        discount: this.discount
      });
      this.$toast.success("Desconto e acréscimo alterados.");
      this.$emit("change");
      this.close();
    }
  }
};
</script>

<style>
</style>
