import OrdersIndex from '@/routes/orders';
import OrdersForm from '@/routes/orders/form';
import OrdersShow from '@/routes/orders/show';

export default [{
  path: '/orders',
  name: 'orders',
  component: OrdersIndex
}, {
  path: '/orders/new',
  name: 'orders-new',
  component: OrdersForm
}, {
  path: '/orders/:id',
  name: 'orders-show',
  component: OrdersShow
}];