import defaultClient from './default';

export default {
  getAll(productId) {
    return defaultClient.get(`products/${productId}/images`);
  },
  upload(productId, data) {
    return defaultClient.upload(`products/${productId}/images`, data);
  },
  remove(productId, id) {
    return defaultClient.delete(`products/${productId}/images/${id}`);
  },
};