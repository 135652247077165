<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Categoria de produtos</h3>
        </div>
        <data-table resource="product-categories" :actions="tableActions" :columns="tableColumns" />
      </div>
    </div>
  </div>
</template>

<script>
import dataTable from "@/components/datatable";

export default {
  components: {
    dataTable
  },
  data: function() {
    return {
      tableColumns: [
        { column: "name", name: "Nome", route: "product-categories-edit" }
      ],
      tableActions: ["edit", "remove"]
    };
  }
};
</script>

<style>
</style>
