<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Estoque</h3>
        </div>
        <data-table
          ref="stock-data-table"
          resource="products"
          :columns="tableColumns"
          :actions="tableActions"
          :disableNew="true"
          :disablePagination="true"
          customPath="products/stock"
        />
      </div>
    </div>
    <update-product-stock-modal ref="update-product-stock-modal" @change="updatedStock" />
  </div>
</template>

<script>
import dataTable from "@/components/datatable";
import updateProductStockModal from "@/components/update-product-stock-modal";

export default {
  components: {
    dataTable,
    updateProductStockModal
  },
  data: function() {
    return {
      currentProduct: null,
      tableColumns: [
        { column: "name", name: "Nome", route: "products-edit" },
        { column: "disponibility.name", name: "Disponibilidade" },
        { column: "available_quantity", name: "Qtde. disponível" }
      ],
      tableActions: [
        {
          icon: "fe-edit-3",
          title: "Editar estoque",
          action: this.updateStock
        }
      ]
    };
  },
  methods: {
    updateStock(row) {
      this.$refs["update-product-stock-modal"].open(row);
    },
    updatedStock() {
      this.$refs["stock-data-table"].getData();
    }
  }
};
</script>

<style>
</style>
