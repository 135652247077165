<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title" v-if="$route.params.id">
            Editar produto - {{ product.name }}
          </h3>
          <h3 class="card-title" v-else>Novo produto - {{ product.name }}</h3>
        </div>
        <center v-if="isLoading">Carregando...</center>
        <form @submit.prevent="save" v-else>
          <div class="card-body pt-0">
            <b-tabs content-class="mt-3">
              <b-tab title="Cadastro" active>
                <form-input
                  label="Nome"
                  v-model="product.name"
                  :required="true"
                  :autofocus="true"
                />
                <form-remote-select
                  label="Categoria"
                  data="product_categories"
                  :value="product.category_id"
                  v-model="product.category_id"
                  :required="true"
                />
                <form-input
                  :textarea="true"
                  rows="10"
                  label="Descrição"
                  v-model="product.description"
                  :required="true"
                />
              </b-tab>
              <b-tab title="Imagens">
                <div
                  v-if="!$route.params.id"
                  class="alert alert-icon alert-primary"
                  role="alert"
                >
                  <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i>
                  Você deve salvar o produto primeiro antes de enviar alguma
                  imagem.
                </div>
                <div v-else>
                  <div class="row mb-3">
                    <div class="col-md-12">
                      <image-picker
                        :productId="product.id"
                        @change="imagesUploaded"
                      />
                    </div>
                  </div>
                  <p v-if="isLoadingImages">Carregando imagens...</p>
                  <div v-else class="row row-cards row-deck">
                    <div
                      v-for="image in images"
                      :key="image.id"
                      class="col-md-2"
                    >
                      <div class="card">
                        <a :href="image.url" target="_blank">
                          <img
                            :src="image.url"
                            class="card-img-top"
                            height="100"
                          />
                        </a>
                        <div class="d-flex align-items-center p-2">
                          <button
                            type="button"
                            class="btn btn-sm btn-danger ml-auto"
                            @click="removeImage(image)"
                          >
                            <i class="fe fe-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="alert alert-icon alert-primary" role="alert">
                        <i
                          class="fe fe-alert-triangle mr-2"
                          aria-hidden="true"
                        ></i>
                        Alterações feitas nessa página são salvas
                        automaticamente.
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Informações">
                <div class="row">
                  <div class="col-md-3">
                    <form-input label="Peso" v-model="product.weight" />
                  </div>
                  <div class="col-md-3">
                    <form-input
                      label="Unidade de medida"
                      v-model="product.measurement_unit"
                    />
                  </div>
                  <div class="col-md-3">
                    <form-input label="Validade" v-model="product.validity" />
                  </div>
                  <div class="col-md-3">
                    <form-input
                      label="Forma de armazenamento"
                      v-model="product.storage_form"
                    />
                  </div>
                </div>
              </b-tab>
              <b-tab title="Preço & Disponbilidade">
                <form-checkbox
                  label="Usar variações"
                  v-model="product.using_variations"
                  @change="changedUsesVariation()"
                />
                <div v-if="!product.using_variations">
                  <div class="row">
                    <div class="col-md-6">
                      <form-remote-select
                        label="Disponibilidade"
                        data="product_disponibilities"
                        :value="product.disponibility_id"
                        v-model="product.disponibility_id"
                        :required="!product.using_variations"
                      />
                    </div>
                    <div class="col-md-6">
                      <form-input
                        label="Quantidade disponível"
                        type="number"
                        min="0"
                        v-model="product.available_quantity"
                        :required="!product.using_variations"
                      />
                    </div>
                  </div>
                  <form-money-input
                    label="Valor"
                    v-model="product.price"
                    :required="!product.using_variations"
                  />
                </div>
                <div v-else class="row">
                  <div class="col-md-10">
                    <label class="form-label">Variações</label>
                    <table class="table table-sm mb-0 variations-table">
                      <thead>
                        <tr>
                          <th>
                            Nome
                            <small>*</small>
                          </th>
                          <th>
                            Valor
                            <small>*</small>
                          </th>
                          <th>
                            Disponibilidade
                            <small>*</small>
                          </th>
                          <th>
                            Quantidade disponível
                            <small>*</small>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(variation, index) in variations"
                          :key="index"
                        >
                          <td>
                            <form-input
                              v-model="variation.name"
                              :required="product.using_variations"
                            />
                          </td>
                          <td>
                            <form-money-input
                              v-model="variation.price"
                              :required="product.using_variations"
                            />
                          </td>
                          <td>
                            <form-remote-select
                              data="product_disponibilities"
                              :value="variation.disponibility_id"
                              v-model="variation.disponibility_id"
                              :required="product.using_variations"
                            />
                          </td>
                          <td>
                            <form-input
                              type="number"
                              min="0"
                              v-model="variation.available_quantity"
                              :required="product.using_variations"
                            />
                          </td>
                          <td style="vertical-align: middle">
                            <button
                              :disabled="variations.length === 1"
                              type="button"
                              class="btn btn-sm btn-danger"
                              @click="removeVariation(variation)"
                            >
                              <i class="fe fe-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      type="button"
                      class="btn btn-sm btn-secondary"
                      @click="addVariation()"
                    >
                      <i class="fe fe-plus"></i>
                    </button>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Status">
                <div class="card">
                  <div class="card-status bg-orange"></div>
                  <div class="card-header">
                    <div class="card-title">Publicado</div>
                  </div>
                  <div class="card-body p-4">
                    <div class="row">
                      <div class="col-md-10">
                        <p>
                          Quando publicado, o produto fica visível no aplicativo
                          para os clientes.
                        </p>
                      </div>
                      <div class="col-md-2">
                        <label class="custom-switch float-right">
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            class="custom-switch-input"
                            v-model="product.published"
                          />
                          <span class="custom-switch-indicator"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" v-if="$route.params.id">
                  <div class="card-status bg-red"></div>
                  <div class="card-header">
                    <div class="card-title">Remover</div>
                  </div>
                  <div class="card-body p-4">
                    <div class="row">
                      <div class="col-md-10">
                        <p>
                          Remover este produto fará com que ele não fique mais
                          vísivel na plataforma.
                          <br />Essa ação não poderá ser desfeita.
                        </p>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="btn btn-danger float-right"
                          @click="remove()"
                        >
                          Remover
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div class="card-footer text-right">
            <div class="d-flex">
              <router-link class="btn btn-link" :to="{ name: 'products' }"
                >Cancelar</router-link
              >
              <form-save-btn
                class="ml-auto"
                label="Salvar"
                :isLoading="isSaving"
              />
            </div>
          </div>
        </form>
      </div>
      <small>* campos obrigatórios</small>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import formInput from "@/components/form-input";
import formCheckbox from "@/components/form-checkbox";
import imagePicker from "@/components/image-picker";
import formMoneyInput from "@/components/form-money-input";
import formSaveBtn from "@/components/form-save-btn";
import formRemoteSelect from "@/components/form-remote-select";
import productClient from "@/clients/product";
import productImageClient from "@/clients/productImage";

export default {
  beforeRouteLeave(to, from, next) {
    if (!this.isSaving) {
      const answer = window.confirm(
        "Você não salvou este produto, tem certeza que deseja sair?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  components: {
    formInput,
    formCheckbox,
    imagePicker,
    formMoneyInput,
    formSaveBtn,
    formRemoteSelect,
    BTabs,
    BTab,
  },
  data: function () {
    return {
      isLoading: true,
      isLoadingImages: true,
      isSaving: false,
      product: {
        price: 0,
      },
    };
  },
  computed: {
    variations() {
      return this.product.variations.filter((v) => !v.removed);
    },
  },
  methods: {
    async save() {
      this.isSaving = true;
      if (this.$route.params.id) {
        await productClient.update(this.$route.params.id, this.product);
      } else {
        await productClient.create(this.product);
      }
      this.$toast.success("Produto salvo.");
      this.$router.push({ name: "products" });
    },
    async getData() {
      if (this.$route.params.id) {
        this.product = await productClient.getOne(this.$route.params.id);
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
    async getImages() {
      if (this.$route.params.id) {
        this.isLoadingImages = true;
        this.images = await productImageClient.getAll(this.$route.params.id);
        this.isLoadingImages = false;
      }
    },
    imagesUploaded() {
      this.$toast.success("Imagens salvas.");
      this.getImages();
    },
    async removeImage(image) {
      const shouldDelete = confirm(
        "Você tem certeza que deseja remover essa imagem?"
      );
      if (shouldDelete) {
        await productImageClient.remove(this.product.id, image.id);
        this.$toast.success("Imagem removida.");
        this.getImages();
      }
    },
    addVariation() {
      const variations = this.product.variations || [];
      variations.push({
        name: "",
        price: 0,
        disponibility_id: null,
        available_quantity: 0,
      });
      this.$set(this.product, "variations", variations);
    },
    removeVariation(variation) {
      const variations = this.product.variations || [];
      if (variation.id) {
        this.$set(this.product.variations, variations.indexOf(variation), {
          ...variation,
          removed: true,
        });
      } else {
        variations.splice(variations.indexOf(variation), 1);
        this.$set(this.product, "variations", variations);
      }
    },
    changedUsesVariation() {
      if (this.product.using_variations) {
        this.product.price = 0;
        this.product.disponibility_id = null;
        this.product.available_quantity = 0;
        this.addVariation();
      } else {
        this.$set(this.product, "variations", []);
      }
    },
    async remove() {
      const result = confirm(
        "Você tem certeza que deseja remover este produto?"
      );
      if (result) {
        await productClient.remove(this.$route.params.id);
        this.$toast.success("Produto removido.");
        this.isSaving = true;
        this.$router.push({ name: "products" });
      }
    },
  },
  mounted() {
    this.getData();
    this.getImages();
  },
};
</script>

<style>
.variations-table .form-group {
  margin-bottom: 0;
}
</style>
