<template>
  <div class="row">
    <div class="col col-login mx-auto">
      <div class="text-center mb-6">
        <img src="../../assets/logo.png" class="login-logo" alt="logo" />
      </div>
      <form @submit.prevent="login" class="card">
        <div class="card-body p-6">
          <div class="card-title">Acesso restrito</div>
          <div v-if="showError" class="alert alert-danger" role="alert">Usuário ou senha incorretos!</div>
          <form-input type="email" label="E-mail" :required="true" v-model="email" :autofocus="true" />
          <form-input type="password" label="Senha" :required="true" v-model="password" />
          <div class="form-footer">
            <button type="submit" :disabled="isLoading" class="btn btn-primary btn-block">Entrar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import authService from "@/services/auth";
import formInput from "@/components/form-input";
import client from "@/clients/default";

export default {
  components: {
    formInput
  },
  data: function() {
    return {
      isLoading: false,
      showError: false,
      email: "",
      password: ""
    };
  },
  methods: {
    async login() {
      this.isLoading = true;
      this.showError = false;

      try {
        const result = await client.post("auth", {
          email: this.email,
          password: this.password
        });
        if (!result) {
          this.isLoading = false;
          return (this.showError = true);
        }
        authService.set(result);
        this.$router.push({ name: "home" });
      } catch (e) {
        this.isLoading = false;
        this.showError = true;
      }
    }
  }
};
</script>

<style>
.login-logo {
  height: 100px;
}
</style>
