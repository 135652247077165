<template>
  <div :class="{'form-group': !small}">
    <label v-if="label" class="form-label">
      {{label}}
      <small v-if="required">*</small>
    </label>
    <input
      :type="_type"
      class="form-control"
      :class="{'form-control-sm': small}"
      :placeholder="_placeHolder"
      :value="value"
      :required="required"
      @input="updateValue($event.target.value)"
      @blur="blur"
      v-mask="mask"
    />
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask,
  },
  name: "form-masked-input",
  props: ["label", "value", "required", "small", "type", "mask"],
  computed: {
    _type() {
      return this.type || "text";
    },
    _placeHolder() {
      return this.mask.replace(/#/g, "0");
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>

<style>
</style>
