import Index from '@/routes/products/index';
import Form from '@/routes/products/form';
import Stock from '@/routes/products/stock';

export default [{
  path: '/products',
  name: 'products',
  component: Index
}, {
  path: '/products/new',
  name: 'products-new',
  component: Form
}, {
  path: '/products/stock',
  name: 'products-stock',
  component: Stock
}, {
  path: '/products/:id',
  name: 'products-edit',
  component: Form
}];