<template>
  <b-modal
    :id="id"
    title="Histórico"
    header-close-content
    ok-only
    ok-title="Fechar"
    @show="reset"
  >
    <center v-if="isLoading">Carregando...</center>
    <table v-else class="table">
      <thead>
        <tr>
          <th>Status</th>
          <th>Usuário</th>
          <th>Data</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in history" :key="data.id">
          <td>{{getStatusLabel(data.new_status)}}</td>
          <td>{{data.user.name}}</td>
          <td>{{data.created_at | datetime}}</td>
        </tr>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
import orderClient from "@/clients/order";
import orderStatusUtil from "@/utils/order-status";
import { VBModal } from "bootstrap-vue";

export default {
  name: "order-status-modal",
  props: ["order"],
  directives: {
    "b-modal": VBModal
  },
  data: function() {
    return {
      isLoading: true,
      history: [],
    };
  },
  computed: {
    id() {
      return 'order-history';
    }
  },
  methods: {
    open() {
      this.$bvModal.show(this.id);
    },
    close() {
      this.$bvModal.hide(this.id);
    },
    async getData() {
      this.history = await orderClient.getHistory(this.order.id);
      this.isLoading = false;
    },
    reset() {
      this.isLoading = true;
      this.history = [];
      this.getData();
    },
    getStatusLabel(status) {
      return orderStatusUtil.get(status).name;
    }
  }
};
</script>

<style>
</style>
