import Vue from 'vue';
import Router from 'vue-router';
import authService from "@/services/auth";
import loginRoutes from '@/routes/login/routes';
import homeRoutes from '@/routes/home/routes';
import calendarRoutes from '@/routes/calendar/routes';
import productsRoutes from '@/routes/products/routes';
import ordersRoutes from '@/routes/orders/routes';
import customersRoutes from '@/routes/customers/routes';
import productCategoriesRoutes from '@/routes/product-categories/routes';
import productDisponibilityRoutes from '@/routes/product-disponibilities/routes';
import paymentTypesRoutes from '@/routes/payment-types/routes';
import deliveryTypesRoutes from '@/routes/delivery-types/routes';
import deliveryPeriodsRoutes from '@/routes/delivery-periods/routes';
import profileRoutes from '@/routes/profile/routes';
import usersRoutes from '@/routes/users/routes';

Vue.use(Router);

const appRouter = new Router({
  routes: [
    ...loginRoutes,
    ...homeRoutes,
    ...calendarRoutes,
    ...productsRoutes,
    ...ordersRoutes,
    ...customersRoutes,
    ...productCategoriesRoutes,
    ...productDisponibilityRoutes,
    ...paymentTypesRoutes,
    ...deliveryTypesRoutes,
    ...deliveryPeriodsRoutes,
    ...profileRoutes,
    ...usersRoutes,
    {
      path: '*',
      redirect: '/home'
    }
  ],
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
});

appRouter.beforeEach((to, from, next) => {
  const isAuthenticated = !!authService.getToken();
  if (to.name !== 'login' && !isAuthenticated) next({
    name: 'login'
  })
  else next()
})

export default appRouter;