<template>
  <div class="table-responsive mt-2">
    <div class="p-3">
      <div class="row">
        <div class="col-md-4">
          <div class="input-icon mb-3">
            <form @submit.prevent="doSearch">
              <input type="text" class="form-control" placeholder="Procurar..." v-model="search" />
              <span class="input-icon-addon">
                <i class="fe fe-search"></i>
              </span>
            </form>
          </div>
        </div>
        <div class="col-md-8">
          <div class="ml-auto text-right">
            <router-link
              v-for="(button, index) in topButtons"
              :key="`btn-${index}`"
              class="btn mr-2"
              :class="`btn-${button.type}`"
              :to="{ name: button.route }"
            >
              <i class="fe" :class="[button.icon]" v-if="button.icon"></i>
              {{button.label}}
            </router-link>
            <router-link class="btn btn-primary" :to="{ name: createRoute }" v-if="!disableNew">
              <i class="fe fe-plus"></i> Novo
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="dataTables_wrapper no-footer">
      <table class="table card-table table-vcenter text-nowrap no-footer">
        <thead>
          <tr>
            <th
              v-for="column in columns"
              :key="column.column"
              :class="{'w-1': column.small}"
            >{{column.name}}</th>
            <th class="w-1"></th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td :colspan="columns.length + 1">
              <center>Carregando...</center>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(row, index) in data.data" :key="row.id + index">
            <td v-for="column in columns" :key="row.id + column.column">
              <router-link
                v-if="column.route"
                :to="{ name: column.route, params: { id: row.id } }"
              >{{displayColumnValue(column, row)}}</router-link>
              <order-status-tag
                v-else-if="column.format === 'order-status-tag'"
                :status="displayColumnValue(column, row)"
              />
              <order-paid-tag
                v-else-if="column.format === 'order-paid-tag'"
                :paid="displayColumnValue(column, row)"
              />
              <span v-else>{{displayColumnValue(column, row)}}</span>
            </td>
            <td>
              <div class="btn-group">
                <router-link
                  v-for="(action, index) in _actions.routes"
                  :key="`route-${index}`"
                  class="btn btn-sm btn-secondary"
                  :to="{ name: action.route, params: { id: row.id } }"
                  v-b-tooltip.hover
                  :title="action.title"
                >
                  <i class="fe" :class="[action.icon]"></i>
                </router-link>
                <button
                  v-for="(action, index) in _actions.actions"
                  :key="`action-${index}`"
                  class="btn btn-sm btn-secondary"
                  v-b-tooltip.hover
                  :title="action.title"
                  type="button"
                  @click="action.action(row)"
                >
                  <i class="fe" :class="[action.icon]"></i>
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="!data.data.length">
            <td :colspan="columns.length + 1">
              <center>Nenhum resultado.</center>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="dataTables_info" v-if="disablePagination">Exibindo {{data.data.length}} registros</div>
      <div class="dataTables_info" v-if="!disablePagination">Exibindo {{data.data.length}} de {{data.total}} registros</div>
      <div class="dataTables_paginate paging_simple_numbers" v-if="!disablePagination">
        <a
          class="paginate_button previous"
          :class="{'disabled': !navigationHasPrevious}"
          @click="navigate(currentPage - 1)"
        >Anterior</a>
        <span>
          <a
            v-for="index in data.lastPage"
            :key="index"
            @click="navigate(index)"
            :class="{'current': index === data.page}"
            class="paginate_button"
          >{{index}}</a>
        </span>
        <a
          class="paginate_button next"
          :class="{'disabled': !navigationHasNext}"
          @click="navigate(currentPage + 1)"
        >Próximo</a>
      </div>
    </div>
    <remove-modal ref="remove-modal" :resource="resource" @change="removedData" />
  </div>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import clients from "@/clients/resource-mapping";
import defaultClient from "@/clients/default";
import orderStatusTag from "@/components/order-status-tag";
import orderPaidTag from "@/components/order-paid-tag";
import removeModal from "@/components/remove-modal";
import numberUtils from "@/utils/number";
import dateUtils from "@/utils/date";

function getPathData(obj, path) {
  return path.split(".").reduce((o, i) => o[i], obj);
}

export default {
  name: "data-table",
  props: [
    "name",
    "columns",
    "resource",
    "client",
    "disableNew",
    "actions",
    "customPath",
    "customFilter",
    "topButtons",
    "disablePagination"
  ],
  directives: {
    "b-tooltip": VBTooltip
  },
  components: {
    orderStatusTag,
    orderPaidTag,
    removeModal
  },
  computed: {
    createRoute() {
      return `${this.resource}-new`;
    },
    editRoute() {
      return `${this.resource}-edit`;
    },
    showRoute() {
      return `${this.resource}-show`;
    },
    navigationHasPrevious() {
      return this.data.page > 1;
    },
    navigationHasNext() {
      return this.data.lastPage > 1 && this.data.page < this.data.lastPage;
    },
    _actions() {
      const defaultActions = [
        {
          name: "edit",
          icon: "fe-edit",
          title: "Editar",
          route: this.editRoute
        },
        {
          name: "show",
          icon: "fe-search",
          title: "Detalhes",
          route: this.showRoute
        },
        {
          name: "remove",
          icon: "fe-trash",
          title: "Remover",
          action: this.removeItem
        }
      ];
      const actions = this.actions || ["edit"];
      return {
        routes: defaultActions.filter(
          a => actions.includes(a.name) && !!a.route
        ),
        actions: defaultActions
          .filter(a => actions.includes(a.name) && !!a.action)
          .concat(actions.filter(a => typeof a === "object"))
      };
    }
  },
  data: function() {
    return {
      isLoading: true,
      currentPage: 1,
      search: "",
      data: {
        data: []
      }
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const queryParam = {
        search: this.search,
        page: this.currentPage,
        ...(this.customFilter || {})
      };
      if (this.customPath) {
        this.data = await defaultClient.get(this.customPath, queryParam);
      } else {
        this.data = await clients[this.resource].getAll(queryParam);
      }
      if(this.disablePagination) {
        this.data = {
          data: this.data
        };
      }
      this.isLoading = false;
    },
    displayColumnValue(column, row) {
      const data = getPathData(row, column.column);
      if (column.format === "money") {
        return numberUtils.formatMoney(data);
      } else if (column.format === "date") {
        return dateUtils.formatDate(data);
      } else if (column.format === "datetime") {
        return dateUtils.formatDateTime(data);
      } else if (column.format === "yesno") {
        return data ? "Sim" : "Não";
      }
      return data;
    },
    doSearch() {
      this.getData();
    },
    navigate(page) {
      this.currentPage = page;
      this.getData();
    },
    removeItem(row) {
      this.$refs["remove-modal"].open(row.id, row.name);
    },
    removedData() {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style>
</style>
