<template>
  <div>
    <div class="page-header">
      <h1 class="page-title">Novo pedido</h1>
    </div>
    <div class="row">
      <div class="col-12">
        <form @submit.prevent="save">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Cliente</h3>
            </div>
            <div class="card-body">
              <div class="row" v-show="!newCustomer">
                <div class="col-md-8">
                  <form-autocomplete
                    ref="customer_search"
                    label="Cliente"
                    data="customers"
                    v-model="customer"
                    :required="!newCustomer"
                    :autofocus="true"
                    @search="changedSearch"
                  />
                </div>
                <div class="col-md-4">
                  <button type="button" class="btn btn-primary mt-5" @click="toggleNewCustomer()">
                    <i class="fe fe-plus"></i> Novo
                  </button>
                </div>
              </div>
              <div class="row" v-show="newCustomer">
                <div class="col-md-4">
                  <form-input
                    ref="new_customer_name"
                    label="Nome"
                    placeholder="Nome completo"
                    v-model="customer.name"
                    :required="newCustomer"
                  />
                </div>
                <div class="col-md-2">
                  <form-masked-input
                    label="Celular"
                    v-model="customer.phone"
                    mask="(##) #####-####"
                    :required="newCustomer"
                  />
                </div>
                <div class="col-md-2">
                  <button type="button" class="btn btn-primary mt-5" @click="toggleNewCustomer()">
                    <i class="fe fe-search"></i> Procurar
                  </button>
                </div>
              </div>
              <div class="row" v-if="!deliveryType || !deliveryType.is_pickup">
                <div class="col-md-2">
                  <form-remote-select
                    v-if="customer && customer.id"
                    label="Endereços cadastrados"
                    data="user_addresses"
                    :filters="{user_id: customer.id}"
                    :getFullModel="true"
                    :additionalFields="['postal_code', 'address', 'number', 'complement', 'neighborhood', 'city', 'state']"
                    v-model="address"
                    @input="changedAddress()"
                  />
                  <form-input v-else label="Nome do endereço" v-model="address.name" />
                </div>
                <div class="col-md-2">
                  <form-masked-input
                    label="CEP"
                    v-model="address.postal_code"
                    mask="##.###-###"
                    @blur="getPostalCodeAddress()"
                  />
                </div>
                <div class="col-md-6">
                  <form-input label="Rua" v-model="address.address" :required="true" />
                </div>
                <div class="col-md-2">
                  <form-input
                    ref="address_number"
                    label="Número"
                    v-model="address.number"
                    :required="true"
                  />
                </div>
                <div class="col-md-3">
                  <form-input label="Complemento" v-model="address.complement" />
                </div>
                <div class="col-md-3">
                  <form-input label="Bairro" v-model="address.neighborhood" :required="true" />
                </div>
                <div class="col-md-3">
                  <form-input label="Cidade" v-model="address.city" :required="true" />
                </div>
                <div class="col-md-3">
                  <form-input label="Estado" v-model="address.state" :required="true" />
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Pagamento & Entrega</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <form-remote-select
                    label="Forma de pagamento"
                    data="payment_types"
                    :additionalFields="['rate_percentage']"
                    :getFullModel="true"
                    :value="paymentType"
                    :helpText="paymentTypeHelperText"
                    :required="true"
                    v-model="paymentType"
                    @input="setPaymentTypeAddition"
                  />
                </div>
                <div class="col-md-4">
                  <form-remote-select
                    label="Forma de entrega"
                    data="delivery_types"
                    :additionalFields="['price', 'is_pickup']"
                    :getFullModel="true"
                    :value="deliveryType"
                    :helpText="deliveryTypeHelperText"
                    :required="true"
                    v-model="deliveryType"
                    @input="changedDeliveryType"
                  />
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-7">
                      <div class="form-group">
                        <label class="form-label">
                          Data de entrega
                          <small>*</small>
                        </label>
                        <date-picker
                          v-model="order.delivery_date"
                          format="DD/MM/YYYY"
                          input-class="form-control"
                          :input-attr="{ required: true }"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <form-remote-select
                        label="Período"
                        data="delivery_periods"
                        :value="order.delivery_period_id"
                        :required="true"
                        v-model="order.delivery_period_id"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Produtos</h3>
              <div class="card-options">
                <button type="button" class="btn btn-sm btn-primary" @click="addProduct()">
                  <i class="fe fe-plus"></i>
                </button>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <table class="table table-hover table-outline table-vcenter text-nowrap card-table order-products-table">
                <tbody>
                  <tr>
                    <th>Produto</th>
                    <th class="text-center" style="width: 10%">Qtde.</th>
                    <th class="text-right" style="width: 1%">Valor</th>
                    <th class="text-right" style="width: 1%">Total</th>
                    <th style="width: 1%"></th>
                  </tr>
                  <tr v-for="(item, index) in order.products" :key="index">
                    <td>
                      <div class="row">
                        <div
                          :class="{'col-md-8': item.product.using_variations, 'col-md-12': !item.product.using_variations}"
                        >
                          <form-autocomplete
                            data="products"
                            v-model="item.product"
                            :required="true"
                            @input="changedProduct(item)"
                            :formatSelectLabel="formatProductLabel"
                          />
                        </div>
                        <div class="col-md-4" v-if="item.product.using_variations">
                          <form-select
                            placeholder = "Variação"
                            :options="item.product.variations"
                            v-model="item.variation_id"
                            :required="item.product.using_variations"
                            :formatSelectLabel="formatVariationLabel"
                            @input="changedVariation(item)"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <form-input v-model="item.quantity" min="1" type="number" />
                    </td>
                    <td class="text-right">{{item.product && item.product.price | money}}</td>
                    <td
                      class="text-right"
                    >{{item.product && item.quantity && item.quantity * item.product.price | money}}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-link text-red"
                        title="Remover item"
                        @click="removeProduct(item)"
                        :disabled="order.products.length === 1"
                      >
                        <i class="fe fe-trash"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="font-w600 text-right">Subtotal</td>
                    <td class="text-right">{{subtotalValue | money}}</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="font-w600 text-right">Entrega</td>
                    <td class="text-right">
                      <form-money-input v-model="order.delivery_price" type="number" />
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="font-w600 text-right">Acréscimo</td>
                    <td class="text-right">
                      <form-money-input v-model="order.addition" type="number" />
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="font-w600 text-right">Desconto</td>
                    <td class="text-right">
                      <form-money-input v-model="order.discount" type="number" />
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="3" class="font-weight-bold text-uppercase text-right">Total</td>
                    <td class="font-weight-bold text-right">{{totalValue | money}}</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Anotações
                <small class="card-title-small">(privado)</small>
              </h3>
            </div>
            <div class="card-body">
              <form-input :textarea="true" rows="5" v-model="order.annotations" />
            </div>
          </div>

          <div class="card">
            <div class="card-footer text-right">
              <div class="d-flex">
                <router-link class="btn btn-link" :to="{ name: 'orders' }">Cancelar</router-link>
                <form-save-btn class="ml-auto" label="Salvar" :isLoading="isSaving" />
              </div>
            </div>
          </div>
          <small>* campos obrigatórios</small>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pt-br";
import "vue2-datepicker/index.css";
import formInput from "@/components/form-input";
import formMaskedInput from "@/components/form-masked-input";
import formMoneyInput from "@/components/form-money-input";
import formAutocomplete from "@/components/form-autocomplete";
import formSelect from "@/components/form-select";
import formRemoteSelect from "@/components/form-remote-select";
import formSaveBtn from "@/components/form-save-btn";
import orderClient from "@/clients/order";
import postalCodeClient from "@/clients/postalCode";
import numberUtils from "@/utils/number";

export default {
  beforeRouteLeave (to, from, next) {
    if(!this.isSaving) {
      const answer = window.confirm(
        "Você não salvou este pedido, tem certeza que deseja sair?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  components: {
    DatePicker,
    formInput,
    formSaveBtn,
    formMoneyInput,
    formAutocomplete,
    formMaskedInput,
    formSelect,
    formRemoteSelect,
  },
  watch: {
    subtotalValue() {
      this.setPaymentTypeAddition();
    },
  },
  computed: {
    paymentTypeHelperText() {
      return (
        this.paymentType &&
        this.paymentType.rate_percentage &&
        `Taxa: ${numberUtils.formatDecimal(this.paymentType.rate_percentage)}%`
      );
    },
    deliveryTypeHelperText() {
      return (
        this.deliveryType &&
        this.deliveryType.price &&
        `Taxa: ${numberUtils.formatMoney(this.deliveryType.price)}`
      );
    },
    subtotalValue() {
      return this.order.products.reduce(
        (p, c) => p + Number(c.product.price) * Number(c.quantity),
        0
      );
    },
    totalValue() {
      let value = 0;
      value += this.subtotalValue;

      value -= Number(this.order.discount);
      value += Number(this.order.addition);

      value += Number(this.order.delivery_price);

      if (value < 0) {
        value = 0;
      }

      return value;
    },
  },
  data: function () {
    return {
      isSaving: false,
      newCustomer: false,
      customerSeach: '',
      customer: {
        id: "",
        name: "",
        phone: "",
      },
      paymentType: {},
      deliveryType: {},
      address: {
        id: "",
        name: "",
        postal_code: "",
        address: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "Joinville",
        state: "SC",
      },
      order: {
        delivery_period_id: null,
        delivery_date: "",
        products: [],
        addition: 0,
        discount: 0,
        delivery_price: 0,
        annotations: "",
      },
    };
  },
  methods: {
    async save() {
      this.isSaving = true;
      const result = await orderClient.create({
        customer: this.customer,
        address: this.address,
        order: {
          ...this.order,
          delivery_type_id: this.deliveryType.id,
          payment_type_id: this.paymentType.id,
        },
      });
      this.$toast.success("Pedido salvo.");
      this.$router.push({ name: "orders-show", params: { id: result.id } });
    },
    async getPostalCodeAddress() {
      let postalCode = (this.address || {}).postal_code;
      if (postalCode && postalCode.length === 10) {
        this.address.address = "...";
        const result = await postalCodeClient.get(postalCode);
        this.address.address = result.logradouro;
        this.address.state = result.uf;
        this.address.city = result.localidade;
        this.address.neighborhood = result.bairro;
        this.$refs.address_number.focus();
      }
    },
    toggleNewCustomer() {
      this.customer = {
        id: "",
        name: this.customerSeach,
        phone: "",
      };
      delete this.address.id;
      this.newCustomer = !this.newCustomer;

      if(this.newCustomer) {
        this.$nextTick(() => {
          this.$refs.new_customer_name.focus();
        });
      } else {
        this.$nextTick(() => {
          this.$refs.customer_search.focus();
        });
      }
    },
    addProduct() {
      const products = this.order.products || [];
      products.push({ product: { price: 0 }, quantity: 1 });
      this.$set(this.order, "products", products);
    },
    removeProduct(item) {
      const result = confirm(
        "Você tem certeza que deseja remover esse item do pedido?"
      );
      if (result) {
        const products = this.order.products || [];
        products.splice(products.indexOf(item), 1);
        this.$set(this.order, "products", products);
      }
    },
    setPaymentTypeAddition() {
      this.order.addition = (
        (Number(this.subtotalValue) / 100) *
        Number(this.paymentType.rate_percentage)
      ).toFixed(2);
    },
    changedDeliveryType() {
      this.order.delivery_price =
        this.deliveryType && this.deliveryType.price
          ? this.deliveryType.price
          : 0;
    },
    changedProduct(item) {
      item.variation_id = null;
      item.quantity = 1;
    },
    changedVariation(item) {
      if (!item.variation_id) return;
      item.product.price = item.product.variations.find(
        (v) => v.id === item.variation_id
      ).price;
    },
    changedAddress() {
      if (!this.address) {
        this.address = {
          id: "",
          name: "",
          postal_code: "",
          address: "",
          number: "",
          complement: "",
          neighborhood: "",
          city: "Joinville",
          state: "SC",
        };
      }
    },
    formatProductLabel(option) {
      let quantity = option.available_quantity;
      if(option.using_variations) {
        quantity = option.variations.reduce((val, curr) => val + curr.available_quantity, 0);
      }
      return `${option.name} (${quantity} disp.)`;
    },
    formatVariationLabel(option) {
      return `${option.name} (${option.available_quantity} disp.)`;
    },
    changedSearch(search) {
      this.customerSeach = search;
    }
  },
  mounted() {
    this.addProduct();
  },
};
</script>

<style>
.order-products-table .form-group {
  margin-bottom: 0;
}
</style>
