<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Formas de entrega</h3>
        </div>
        <data-table resource="delivery-types" :actions="tableActions" :columns="tableColumns" />
      </div>
    </div>
  </div>
</template>

<script>
import dataTable from "@/components/datatable";

export default {
  components: {
    dataTable
  },
  data: function() {
    return {
      tableColumns: [
        { column: "name", name: "Nome", route: "delivery-types-edit" },
        { column: "active", name: "Habilitado", format: "yesno" },
      ],
      tableActions: ["edit", "remove"]
    };
  }
};
</script>

<style>
</style>
