<template>
  <b-modal :id="id" title="Editar estoque" header-close-content @show="reset">
    <p>
      Alterando estoque do produto
      <strong>{{product.name}}</strong>.
    </p>
    <form-remote-select
      label="Disponibilidade"
      data="product_disponibilities"
      :value="disponibility_id"
      v-model="disponibility_id"
      :noEmptyOption="true"
      :required="true"
    />
    <form-input label="Quantidade disponível" type="number" min="0" v-model="available_quantity" />
    <template v-slot:modal-footer="{ cancel }">
      <button
        type="button"
        class="btn btn-secondary"
        :disabled="isLoading"
        @click="cancel()"
      >Cancelar</button>
      <button
        type="button"
        class="btn btn-primary"
        :class="{'btn-loading': isLoading}"
        @click="save()"
      >Salvar</button>
    </template>
  </b-modal>
</template>

<script>
import productClient from "@/clients/product";
import formInput from "@/components/form-input";
import formRemoteSelect from "@/components/form-remote-select";
import { VBModal } from "bootstrap-vue";

export default {
  name: "update-product-stock-modal",
  props: [],
  directives: {
    "b-modal": VBModal
  },
  components: {
    formInput,
    formRemoteSelect
  },
  data: function() {
    return {
      isLoading: false,
      product: {},
      disponibility_id: null,
      available_quantity: 0
    };
  },
  computed: {
    id() {
      return `update-product-stock-modal`;
    }
  },
  methods: {
    open(product) {
      this.product = product;
      this.$bvModal.show(this.id);
    },
    close() {
      this.$bvModal.hide(this.id);
    },
    reset() {
      this.isLoading = false;
      this.available_quantity = this.product.available_quantity;
      this.disponibility_id = this.product.disponibility_id;
    },
    async save() {
      this.isLoading = true;
      // TODO: handle error
      await productClient.updateStock(this.product.id, {
        variation_id: this.product.variation_id,
        disponibility_id: this.disponibility_id,
        available_quantity: this.available_quantity
      });
      this.$toast.success("Estoque atualizado.");
      this.$emit("change");
      this.close();
    }
  }
};
</script>

<style>
</style>
