<template>
  <div :class="{'form-group': !small}">
    <label class="form-label" v-if="label">
      {{label}}
      <small v-if="required">*</small>
    </label>
    <select
      class="form-control"
      :class="{'form-control-sm': small}"
      :required="required"
      @change="change"
    >
      <option v-if="placeholder" value="" disabled selected>{{placeholder}}</option>
      <option
        v-for="(option, index) in _options"
        :key="index"
        :value="option.id"
        :selected="selectedOption(option)"
      >{{ formatSelectLabel ? formatSelectLabel(option) : option.name }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "form-select",
  props: {
    label: String,
    value: String,
    options: Array,
    small: Boolean,
    required: Boolean,
    placeholder: String,
    formatSelectLabel: Function
  },
  computed: {
    _options() {
      const _options = this.options.slice();
      if(!this.placeholder) {
        _options.unshift({});
      }
      return _options;
    },
  },
  methods: {
    selectedOption(option) {
      if (this.value) {
        return option.id == this.value;
      }
      return false;
    },
    change(e) {
      const selectedId = e.target.value;
      this.$emit("input", selectedId);
    },
  },
};
</script>

<style>
</style>
