<template>
  <b-modal size="lg" :id="id" title="Alterar forma de entrega" header-close-content @show="reset">
    <form-remote-select
      label="Forma de entrega"
      data="delivery_types"
      :additionalFields="['price', 'is_pickup']"
      :getFullModel="true"
      :noEmptyOption="true"
      :value="deliveryType"
      v-model="deliveryType"
      :required="true"
    />
    <div class="row" v-if="deliveryType && !deliveryType.is_pickup">
      <div class="col-md-2">
        <form-remote-select
          v-if="order && order.user_id"
          label="Endereços"
          data="user_addresses"
          :filters="{user_id: order.user_id}"
          :getFullModel="true"
          :additionalFields="['postal_code', 'address', 'number', 'complement', 'neighborhood', 'city', 'state']"
          v-model="address"
          @input="changedAddress()"
        />
        <form-input v-else label="Nome" v-model="address.name" />
      </div>
      <div class="col-md-2">
        <form-masked-input
          label="CEP"
          v-model="address.postal_code"
          mask="##.###-###"
          @blur="getPostalCodeAddress()"
          :required="false"
        />
      </div>
      <div class="col-md-6">
        <form-input label="Rua" v-model="address.address" :required="true" />
      </div>
      <div class="col-md-2">
        <form-input ref="address_number" label="Número" v-model="address.number" :required="true" />
      </div>
      <div class="col-md-3">
        <form-input label="Complemento" v-model="address.complement" />
      </div>
      <div class="col-md-3">
        <form-input label="Bairro" v-model="address.neighborhood" :required="true" />
      </div>
      <div class="col-md-3">
        <form-input label="Cidade" v-model="address.city" :required="true" />
      </div>
      <div class="col-md-3">
        <form-input label="Estado" v-model="address.state" :required="true" />
      </div>
    </div>
    <form-checkbox label="Alterar valor do pedido" v-model="changeOrderValue" />
    <p v-if="changeOrderValue && !enablePriceEdit">
      <strong>Valor de entrega:</strong>
      {{deliveryType.price | money}}
      <a
        href="javascript:;"
        class="small"
        v-b-tooltip.hover
        title="Sobrescrever valor de entrega"
        @click="toggleEditPrice()"
      >Editar</a>
      <br />
      <small class="muted">O valor total do pedido será alterado.</small>
    </p>
    <p v-if="changeOrderValue && enablePriceEdit">
      <form-money-input label="Valor da entrega" v-model="overwritePrice" />
      <small class="muted">O valor total do pedido será alterado.</small>
      <a
        href="javascript:;"
        class="small"
        v-b-tooltip.hover
        title="Cancelar edição de valor"
        @click="toggleEditPrice()"
      >Cancelar</a>
    </p>
    <template v-slot:modal-footer="{ cancel }">
      <button
        type="button"
        class="btn btn-secondary"
        :disabled="isLoading"
        @click="cancel()"
      >Cancelar</button>
      <button
        type="button"
        class="btn btn-primary"
        :class="{'btn-loading': isLoading}"
        @click="save()"
      >Salvar</button>
    </template>
  </b-modal>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import formRemoteSelect from "@/components/form-remote-select";
import formMoneyInput from "@/components/form-money-input";
import formCheckbox from "@/components/form-checkbox";
import formInput from "@/components/form-input";
import formMaskedInput from "@/components/form-masked-input";
import orderClient from "@/clients/order";
import postalCodeClient from "@/clients/postalCode";
import { VBModal } from "bootstrap-vue";

export default {
  name: "order-delivery-type-modal",
  props: ["order"],
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  components: {
    formRemoteSelect,
    formCheckbox,
    formMoneyInput,
    formInput,
    formMaskedInput,
  },
  data: function () {
    return {
      isLoading: false,
      deliveryType: {},
      changeOrderValue: true,
      enablePriceEdit: false,
      overwritePrice: 0,
      address: {
        id: "",
        name: "",
        postal_code: "",
        address: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "Joinville",
        state: "SC",
      },
    };
  },
  computed: {
    id() {
      return `order-delivery-type-modal`;
    },
  },
  methods: {
    open() {
      this.$bvModal.show(this.id);
    },
    close() {
      this.$bvModal.hide(this.id);
    },
    reset() {
      this.isLoading = false;
      this.changeOrderValue = true;
      this.enablePriceEdit = false;
      this.overwritePrice = 0;
      this.deliveryType = this.order.deliveryType;
      this.address = this.order.userAddress;
      this.changedAddress();
    },
    toggleEditPrice() {
      this.enablePriceEdit = !this.enablePriceEdit;
      this.overwritePrice = 0;
    },
    async save() {
      if (!this.deliveryType) return;
      this.isLoading = true;
      // TODO: handle error
      await orderClient.updateDeliveryType(this.order.id, {
        delivery_type_id: this.deliveryType.id,
        change_order_value: this.changeOrderValue,
        overwrite_price: this.overwritePrice,
        address: this.address,
      });
      this.$toast.success("Forma de entrega alterada.");
      this.$emit("change");
      this.close();
    },
    async getPostalCodeAddress() {
      let postalCode = (this.address || {}).postal_code;
      if (postalCode && postalCode.length === 10) {
        this.address.address = "...";
        const result = await postalCodeClient.get(postalCode);
        this.address.address = result.logradouro;
        this.address.state = result.uf;
        this.address.city = result.localidade;
        this.address.neighborhood = result.bairro;
        this.$refs.address_number.focus();
      }
    },
    changedAddress() {
      if (!this.address) {
        this.address = {
          id: "",
          name: "",
          postal_code: "",
          address: "",
          number: "",
          complement: "",
          neighborhood: "",
          city: "Joinville",
          state: "SC",
        };
      }
    },
  },
};
</script>

<style>
</style>
