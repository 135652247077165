export default {
  mask(m, s) {
    s = s.split('');
    let l = (m = m.split('')).length,
      j = 0,
      h = '';
    for (let i = -1; ++i < l;)
      if (m[i] != '#') {
        if (m[i] == '\\' && (h += m[++i])) continue;
        h += m[i];
        i + 1 == l && (s[j - 1] += h, h = '');
      }
    else {
      if (!s[j] && !(h = '')) break;
      (s[j] = h + s[j++]) && (h = '');
    }
    return s.join('') + h;
  },

  maskPhone(value) {
    return this.mask('(##) #####-####', value);
  }
}