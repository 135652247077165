<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Clientes</h3>
        </div>
        <data-table
          resource="customers"
          :columns="tableColumns"
          :actions="tableActions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dataTable from "@/components/datatable";

export default {
  components: {
    dataTable
  },
  data: function() {
    return {
      ordersCount: {},
      tableColumns: [{ column: "name", name: "Nome", route: "customers-show" }],
      tableActions: ["show"]
    };
  }
};
</script>

<style>
</style>
