<template>
  <b-modal :id="id" title="Alterar forma de pagamento" header-close-content @show="reset">
    <form-remote-select
      label="Forma de pagamento"
      data="payment_types"
      :additionalFields="['rate_percentage']"
      :getFullModel="true"
      :noEmptyOption="true"
      :value="paymentType"
      v-model="paymentType"
      :required="true"
    />
    <form-checkbox label="Alterar valor do pedido" v-model="changeOrderValue" />
    <p v-if="changeOrderValue && !enablePriceEdit">
      <strong>Porcentagem:</strong>
      {{paymentType.rate_percentage | decimal}}%
      <br />
      <strong>Valor de acréscimo:</strong>
      {{additionValue | money}}
      <a
        href="javascript:;"
        class="small"
        v-b-tooltip.hover
        title="Sobrescrever valor de acréscimo"
        @click="toggleEditPrice()"
      >Editar</a>
      <br />
      <small class="muted">O valor total do pedido será alterado.</small>
    </p>
    <p v-if="changeOrderValue && enablePriceEdit">
      <form-money-input label="Valor do acréscimo" v-model="overwritePrice" />
      <small class="muted">O valor total do pedido será alterado.</small>
      <a
        href="javascript:;"
        class="small"
        v-b-tooltip.hover
        title="Cancelar edição de valor"
        @click="toggleEditPrice()"
      >Cancelar</a>
    </p>
    <template v-slot:modal-footer="{ cancel }">
      <button
        type="button"
        class="btn btn-secondary"
        :disabled="isLoading"
        @click="cancel()"
      >Cancelar</button>
      <button
        type="button"
        class="btn btn-primary"
        :class="{'btn-loading': isLoading}"
        @click="save()"
      >Salvar</button>
    </template>
  </b-modal>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import formRemoteSelect from "@/components/form-remote-select";
import formMoneyInput from "@/components/form-money-input";
import formCheckbox from "@/components/form-checkbox";
import orderClient from "@/clients/order";
import { VBModal } from "bootstrap-vue";

export default {
  name: "order-payment-type-modal",
  props: ["order"],
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip
  },
  components: {
    formRemoteSelect,
    formCheckbox,
    formMoneyInput
  },
  data: function() {
    return {
      isLoading: false,
      paymentType: {},
      changeOrderValue: true,
      enablePriceEdit: false,
      overwritePrice: 0
    };
  },
  computed: {
    id() {
      return `order-payment-type-modal`;
    },
    additionValue() {
      return (
        (Number(this.order.subtotal) / 100) *
        Number(this.paymentType.rate_percentage)
      );
    }
  },
  methods: {
    open() {
      this.$bvModal.show(this.id);
    },
    close() {
      this.$bvModal.hide(this.id);
    },
    reset() {
      this.isLoading = false;
      this.changeOrderValue = true;
      this.enablePriceEdit = false;
      this.overwritePrice = 0;
      this.paymentType = this.order.paymentType;
    },
    toggleEditPrice() {
      this.enablePriceEdit = !this.enablePriceEdit;
      this.overwritePrice = 0;
    },
    async save() {
      if (!this.paymentType) return;
      this.isLoading = true;
      // TODO: handle error
      await orderClient.updatePaymentType(this.order.id, {
        payment_type_id: this.paymentType.id,
        change_order_value: this.changeOrderValue,
        overwrite_price: this.overwritePrice
      });
      this.$toast.success("Forma de pagamento alterada.");
      this.$emit("change");
      this.close();
    }
  }
};
</script>

<style>
</style>
