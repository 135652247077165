<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title" v-if="$route.params.id">Editar período de entrega</h3>
          <h3 class="card-title" v-else>Novo período de entrega</h3>
        </div>
        <center v-if="isLoading">Carregando...</center>
        <form @submit.prevent="save" v-else>
          <div class="card-body">
            <form-input label="Nome" v-model="data.name" :required="true" :autofocus="true" />
            <div class="form-group">
              <label class="custom-switch">
                <input
                  type="checkbox"
                  name="custom-switch-checkbox"
                  class="custom-switch-input"
                  v-model="data.active"
                />
                <span class="custom-switch-indicator"></span>
                <span class="custom-switch-description">Habilitado</span>
              </label>
            </div>
          </div>
          <div class="card-footer text-right">
            <div class="d-flex">
              <router-link class="btn btn-link" :to="{ name: 'delivery-periods' }">Cancelar</router-link>
              <form-save-btn class="ml-auto" label="Salvar" :isLoading="isSaving" />
            </div>
          </div>
        </form>
      </div>
      <small>* campos obrigatórios</small>
    </div>
  </div>
</template>

<script>
import formInput from "@/components/form-input";
import formSaveBtn from "@/components/form-save-btn";
import deliveryPeriodClient from "@/clients/deliveryPeriod";

export default {
  components: {
    formInput,
    formSaveBtn,
  },
  data: function () {
    return {
      isLoading: true,
      isSaving: false,
      data: {
        name: "",
        information: "",
        rate_percentage: 0,
        active: true,
      },
    };
  },
  methods: {
    async save() {
      this.isSaving = true;
      if (this.$route.params.id) {
        await deliveryPeriodClient.update(this.$route.params.id, this.data);
      } else {
        await deliveryPeriodClient.create(this.data);
      }
      this.$toast.success("Período de entrega salvo.");
      this.$router.push({ name: "delivery-periods" });
    },
    async getData() {
      if (this.$route.params.id) {
        this.data = await deliveryPeriodClient.getOne(this.$route.params.id);
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
</style>
