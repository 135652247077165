import productClient from './product';
import orderClient from './order';
import userClient from './user';
import customerClient from './customer';
import productCategoryClient from './productCategory';
import productDisponibilityClient from './productDisponibility';
import paymentTypeClient from './paymentType';
import deliveryTypeClient from './deliveryType';
import deliveryPeriodClient from './deliveryPeriod';

export default {
  products: productClient,
  orders: orderClient,
  'product-categories': productCategoryClient,
  'product-disponibilities': productDisponibilityClient,
  'payment-types': paymentTypeClient,
  'delivery-types': deliveryTypeClient,
  'delivery-periods': deliveryPeriodClient,
  customers: customerClient,
  users: userClient,
};