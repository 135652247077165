<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Calendário</h3>
        </div>
        <div class="card-body">
          <center v-if="isLoading">Carregando...</center>
          <FullCalendar
            v-else
            defaultView="dayGridWeek"
            :header="{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridWeek,dayGridMonth'
            }"
            :plugins="calendarPlugins"
            :locale="ptBRLocale"
            :events="calendarEvents"
            @eventClick="handleClick"
            eventTextColor="#fff"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptBRLocale from "@fullcalendar/core/locales/pt-br";

import orderClient from "@/clients/order";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      isLoading: true,
      calendarPlugins: [dayGridPlugin, interactionPlugin],
      ptBRLocale,
      calendarEvents: [],
    };
  },
  methods: {
    handleClick(info) {
      this.$router.push({ name: "orders-show", params: { id: info.event.id } });
    },
    async getData() {
      this.isLoading = true;
      this.calendarEvents = await orderClient.getCalendar();
      this.isLoading = false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.fc-event {
  cursor: pointer;
}
</style>
