<template>
  <div class="dropbox">
    <input
      type="file"
      multiple
      :name="uploadFieldName"
      :disabled="isSaving"
      @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
      accept="image/*"
      class="input-file"
    />
    <p v-if="isInitial">
      Arraste seus arquivos aqui
      <br />ou clique para selecionar
      <br />
      <small>(min. 400px de largura)</small>
    </p>
    <p v-if="isSaving">Enviando {{ fileCount }} imagens...</p>
    <p v-if="isFailed">Erro: {{uploadError}}</p>
  </div>
</template>

<script>
import productImageClient from "@/clients/productImage";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;
export default {
  name: "file-picker",
  props: ["productId"],
  data() {
    return {
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "image[]",
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadError = null;
    },
    upload(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;

      productImageClient
        .upload(this.productId, formData)
        .then(() => {
          this.currentStatus = STATUS_SUCCESS;
          this.$emit("change", "uploaded");
          this.reset();
        })
        .catch((err) => {
          this.uploadError = err.message;
          this.currentStatus = STATUS_FAILED;
        });
    },
    filesChange(fieldName, fileList) {
      const formData = new FormData();

      if (!fileList.length) return;

      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });

      this.upload(formData);
    },
  },
  mounted() {
    this.reset();
  },
};
</script>

<style>
.dropbox {
  outline: 2px dashed #e9ecef;
  outline-offset: -10px;
  background: #f8f9fa;
  padding: 20px;
  color: dimgray;
  min-height: 100px;
  position: relative;
  cursor: pointer;
  display: flex;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.dropbox:hover {
  outline-color: #467fcf;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  margin: auto;
}
</style>
