<template>
  <center v-if="isLoading">Carregando...</center>
  <div v-else>
    <div class="page-header">
      <h1 class="page-title">Detalhes do Cliente</h1>
      <div class="page-options d-flex">
        <div class="btn-list">
          <a
            :href="`https://api.whatsapp.com/send?phone=55${customer.phone}`"
            target="_blank"
            class="btn btn-success"
            v-b-tooltip.hover
            title="Enviar mensagem whatsapp"
          >
            <i class="fab fa-whatsapp"></i>
          </a>
          <router-link
            class="btn btn-primary"
            v-b-tooltip.hover
            title="Editar"
            :to="{ name: 'customers-edit', params: {id: customer.id} }"
          >
            <i class="fe fe-edit"></i>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row row-cards row-deck">
      <div class="col-12">
        <div class="card">
          <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap">
              <tbody>
                <tr>
                  <td>
                    <strong>Nome:</strong>
                  </td>
                  <td>{{customer.name}}</td>
                </tr>
                <tr>
                  <td>
                    <strong>E-mail:</strong>
                  </td>
                  <td>
                    <a :href="`mailto:${customer.email}`">{{customer.email}}</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Celular:</strong>
                  </td>
                  <td>{{customer.phone | maskphone}}</td>
                </tr>
                <tr>
                  <td style="vertical-align: top;">
                    <strong>Anotações:</strong>
                  </td>
                  <td>
                    <p class="pre-formatted">{{customer.annotations}}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Pedidos</h3>
          </div>
          <div class="table-responsive">
            <data-table
              resource="orders"
              :customFilter="{user_id: customer.id}"
              :columns="tableColumns"
              :actions="tableActions"
              :disableNew="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customerClient from "@/clients/customer";
import dataTable from "@/components/datatable";
import { VBTooltip } from "bootstrap-vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    dataTable,
  },
  data: function () {
    return {
      isLoading: true,
      customer: {},
      tableColumns: [
        { column: "code", name: "Código", route: "orders-show" },
        { column: "user.name", name: "Cliente" },
        { column: "created_at", name: "Data", format: "date" },
        { column: "total", name: "Total", format: "money" },
        { column: "paid", name: "Pago", format: "order-paid-tag" },
        { column: "status", name: "Status", format: "order-status-tag" },
      ],
      tableActions: ["show"],
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.customer = await customerClient.getOne(this.$route.params.id);
      this.isLoading = false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.pre-formatted {
  white-space: pre;
}
</style>
