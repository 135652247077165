export default {
  unformat(value) {
    value = value.replace('R$ ', '');
    value = value.replace(' %', '');
    value = value.replace(/\./g, '');
    value = value.replace(',', '.');
    return value;
  },

  formatMoney(value) {
    return Number(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  },

  formatInteger(value) {
    return Number(value).toFixed(0);
  },

  formatDecimal(value) {
    return Number(value).toLocaleString('pt-BR', );
  }
}