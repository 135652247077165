import defaultClient from './default';

export default {
  getAll(query) {
    return defaultClient.get(`delivery-periods`, query);
  },
  getOne(id) {
    return defaultClient.get(`delivery-periods/${id}`);
  },
  create(data) {
    return defaultClient.post(`delivery-periods`, data);
  },
  update(id, data) {
    return defaultClient.put(`delivery-periods/${id}`, data);
  },
  remove(id) {
      return defaultClient.delete(`delivery-periods/${id}`);
  }

};