<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Configurar automação</h3>
        </div>
        <form @submit.prevent="save">
          <div class="card-body">
            <p class="mb-5">
              Configure aqui a automação da disponibilidade dos produtos para
              quando atingir a quantidade minima em estoque. Quando não atingir
              a quantidade mínima, o padrão será mantido.
            </p>
            <div class="row">
              <div class="col-md-6">
                <form-remote-select
                  label="Disponibilidade padrão"
                  data="product_disponibilities"
                  :value="data.default_disponibility_id"
                  v-model="data.default_disponibility_id"
                  :noEmptyOption="true"
                  :required="true"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <form-input
                  label="Quantidade mínima"
                  type="number"
                  min="0"
                  v-model="data.minimum_available_quantity"
                  :required="true"
                  :autofocus="true"
                />
              </div>
              <div class="col-md-6">
                <form-remote-select
                  label="Nova disponibilidade do produto"
                  data="product_disponibilities"
                  :value="data.disponibility_id"
                  v-model="data.disponibility_id"
                  :noEmptyOption="true"
                  :required="true"
                />
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <div class="d-flex">
              <router-link
                class="btn btn-link"
                :to="{ name: 'product-disponibilities' }"
                >Cancelar</router-link
              >
              <form-save-btn
                class="ml-auto"
                label="Salvar"
                :isLoading="isSaving"
              />
            </div>
          </div>
        </form>
      </div>
      <small>* campos obrigatórios</small>
    </div>
  </div>
</template>

<script>
import formInput from "@/components/form-input";
import formRemoteSelect from "@/components/form-remote-select";
import formSaveBtn from "@/components/form-save-btn";
import productDisponibilityClient from "@/clients/productDisponibility";

export default {
  components: {
    formInput,
    formRemoteSelect,
    formSaveBtn,
  },
  data: function () {
    return {
      isSaving: false,
      data: {
        minimum_available_quantity: 0,
        disponibility_id: null,
        default_disponibility_id: null,
      },
    };
  },
  methods: {
    async save() {
      this.isSaving = true;
      await productDisponibilityClient.updateAutomation(this.data);
      this.$toast.success("Automação salva.");
      this.$router.push({ name: "product-disponibilities" });
    },
    async getData() {
      const data = await productDisponibilityClient.getAutomation();
      if (data) {
        this.data = data;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
</style>
