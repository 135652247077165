import defaultClient from './default';

export default {
  getAll(query) {
    return defaultClient.get(`orders`, query);
  },
  getOne(id) {
    return defaultClient.get(`orders/${id}`);
  },
  create(data) {
    return defaultClient.post(`orders`, data);
  },
  update(id, data) {
    return defaultClient.put(`orders/${id}`, data);
  },
  updateDeliveryType(id, data) {
    return defaultClient.put(`orders/${id}/delivery-type`, data);
  },
  updatePaymentType(id, data) {
    return defaultClient.put(`orders/${id}/payment-type`, data);
  },
  getHistory(id) {
    return defaultClient.get(`orders/${id}/history`);
  },
  getOrdersCount() {
    return defaultClient.get(`orders/count`);
  },
  getCalendar() {
    return defaultClient.get(`orders/calendar`);
  }

};