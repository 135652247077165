<template>
  <div>
    <div class="page-header">
      <h1 class="page-title">{{greeting}} {{user.name}}!</h1>
    </div>
    <center v-if="isLoading">Carregando...</center>
    <div class="row row-card" v-if="!isLoading">
      <div class="col-sm-6 col-lg-3">
        <div class="card p-3">
          <div class="d-flex align-items-center">
            <span class="stamp stamp-md bg-blue mr-3">
              <i class="fe fe-truck"></i>
            </span>
            <div>
              <h4 class="m-0">
                <router-link :to="{ name: 'calendar' }">
                  {{data.deliver_today}}
                  <small>entrega(s) para hoje</small>
                </router-link>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="card p-3">
          <div class="d-flex align-items-center">
            <span class="stamp stamp-md bg-orange mr-3">
              <i class="fe fe-clock"></i>
            </span>
            <div>
              <h4 class="m-0">
                <router-link :to="{ name: 'orders' }">
                  {{data.pending_orders}}
                  <small>pedido(s) aguardando</small>
                </router-link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import authService from "@/services/auth";
import dashboardClient from "@/clients/dashboard";

export default {
  components: {},
  data: function() {
    return {
      isLoading: true,
      greeting: "Olá",
      data: {},
      user: {}
    };
  },
  methods: {
    setGreeting() {
      const hour = moment().hour();
      if (hour >= 6 && hour < 12) {
        this.greeting = "Bom dia";
      } else if (hour >= 12 && hour < 19) {
        this.greeting = "Boa Tarde";
      } else {
        this.greeting = "Boa noite";
      }
    },
    async getData() {
      this.user = authService.getUser();
      if (this.user && this.user.name) {
        this.user.name = this.user.name.split(" ")[0];
      }
      this.data = await dashboardClient.get();
      this.isLoading = false;
    }
  },
  mounted() {
    this.setGreeting();
    this.getData();
  }
};
</script>

<style>
</style>
