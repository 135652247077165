<template>
  <b-modal :id="id" title="Confirmação" header-close-content @show="reset">
    <p>
      Você tem certeza que deseja remover
      <strong>{{data.name}}</strong>?
    </p>
    <template v-slot:modal-footer="{ cancel }">
      <button type="button" class="btn btn-secondary" :disabled="isLoading" @click="cancel()">Não</button>
      <button
        type="button"
        class="btn btn-danger"
        :class="{'btn-loading': isLoading}"
        @click="confirm()"
      >Sim</button>
    </template>
  </b-modal>
</template>

<script>
import clients from "@/clients/resource-mapping";
import { VBModal } from "bootstrap-vue";

export default {
  name: "remove-modal",
  props: ["resource"],
  directives: {
    "b-modal": VBModal
  },
  data: function() {
    return {
      isLoading: false,
      data: {}
    };
  },
  computed: {
    id() {
      return `remove-modal-${this.resource}`;
    }
  },
  methods: {
    open(id, name) {
      this.data = {
        id,
        name
      };
      this.$bvModal.show(this.id);
    },
    close() {
      this.$bvModal.hide(this.id);
    },
    reset() {
      this.isLoading = false;
    },
    async confirm() {
      this.isLoading = true;
      // TODO: handle error
      await clients[this.resource].remove(this.data.id);
      this.$emit("change");
      this.close();
    }
  }
};
</script>

<style>
</style>
