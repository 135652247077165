<template>
  <b-modal :id="id" title="Editar anotações" header-close-content @show="reset">
    <form-input label="Anotações" :textarea="true" rows="10" v-model="annotations" />
    <template v-slot:modal-footer="{ cancel }">
      <button
        type="button"
        class="btn btn-secondary"
        :disabled="isLoading"
        @click="cancel()"
      >Cancelar</button>
      <button
        type="button"
        class="btn btn-primary"
        :class="{'btn-loading': isLoading}"
        @click="save()"
      >Salvar</button>
    </template>
  </b-modal>
</template>

<script>
import formInput from "@/components/form-input";
import orderClient from "@/clients/order";
import { VBModal } from "bootstrap-vue";

export default {
  name: "order-annotations-modal",
  props: ["order"],
  directives: {
    "b-modal": VBModal
  },
  components: {
    formInput
  },
  data: function() {
    return {
      isLoading: false,
      annotations: ""
    };
  },
  computed: {
    id() {
      return `order-annotations-modal`;
    }
  },
  methods: {
    open() {
      this.$bvModal.show(this.id);
    },
    close() {
      this.$bvModal.hide(this.id);
    },
    reset() {
      this.isLoading = false;
      this.annotations = this.order.annotations;
    },
    async save() {
      this.isLoading = true;
      // TODO: handle error
      await orderClient.update(this.order.id, {
        annotations: this.annotations
      });
      this.$toast.success("Anotações salvas.");
      this.$emit("change");
      this.close();
    }
  }
};
</script>

<style>
</style>
