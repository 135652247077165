import Vue from 'vue';
import 'bootstrap';
import App from './App.vue';
import router from './router';
import numberUtils from '@/utils/number';
import dateUtils from '@/utils/date';
import stringUtils from '@/utils/string';
import authService from '@/services/auth';
import {
  ModalPlugin
} from 'bootstrap-vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.config.productionTip = false;

Vue.prototype.$_token = authService.getToken();
authService.startRefresh();

Vue.filter('datetime', function (value) {
  if (!value) return '';
  value = value.toString();
  return dateUtils.formatDateTime(value);
});

Vue.filter('date', function (value) {
  if (!value) return '';
  value = value.toString();
  return dateUtils.formatDate(value);
});

Vue.filter('money', function (value) {
  if (!value && value !== 0) return '';
  value = value.toString();
  return numberUtils.formatMoney(value);
});

Vue.filter('number', function (value) {
  if (!value && value !== 0) return '';
  value = value.toString();
  return numberUtils.formatInteger(value);
});

Vue.filter('decimal', function (value) {
  if (!value && value !== 0) return '';
  value = value.toString();
  return numberUtils.formatDecimal(value);
});

Vue.filter('maskphone', function (value) {
  if (!value) return '';
  value = value.toString();
  return stringUtils.maskPhone(value);
});

Vue.use(ModalPlugin);
Vue.use(VueToast, {
  position: 'top-right'
});

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')