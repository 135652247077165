import Index from '@/routes/delivery-types';
import Form from '@/routes/delivery-types/form';

export default [{
  path: '/delivery-types',
  name: 'delivery-types',
  component: Index
}, {
  path: '/delivery-types/new',
  name: 'delivery-types-new',
  component: Form
}, {
  path: '/delivery-types/:id',
  name: 'delivery-types-edit',
  component: Form
}];