import CategoriesIndex from '@/routes/product-categories';
import CategoriesForm from '@/routes/product-categories/form';

export default [{
  path: '/product-categories',
  name: 'product-categories',
  component: CategoriesIndex
}, {
  path: '/product-categories/new',
  name: 'product-categories-new',
  component: CategoriesForm
}, {
  path: '/product-categories/:id',
  name: 'product-categories-edit',
  component: CategoriesForm
}];