import defaultClient from './default';

export default {
  getAll(query) {
    return defaultClient.get(`products`, query);
  },
  getOne(id) {
    return defaultClient.get(`products/${id}`);
  },
  create(data) {
    return defaultClient.post(`products`, data);
  },
  update(id, data) {
    return defaultClient.put(`products/${id}`, data);
  },
  updateStock(id, data) {
    return defaultClient.put(`products/${id}/stock`, data);
  },
  remove(id) {
    return defaultClient.delete(`products/${id}`);
  },

};