<template>
  <b-modal size="xl" :id="id" title="Editar Itens" header-close-content @show="reset">
    <button type="button" class="btn btn-sm btn-primary float-right mb-2" @click="addProduct()">
      <i class="fe fe-plus"></i>
    </button>
    
    <div class="table-responsive mb-0">
      <form :ref="id+ '-form'" @submit.prevent="onSave">
        <table
          class="table table-hover table-outline table-vcenter text-nowrap card-table order-products-table"
        >
          <tbody>
            <tr>
              <th>Produto</th>
              <th class="text-center" style="width: 10%">Qtde.</th>
              <th class="text-right" style="width: 1%">Valor</th>
              <th class="text-right" style="width: 1%">Total</th>
              <th style="width: 1%"></th>
            </tr>
            <tr v-for="(item, index) in orderCopy.products" :key="index">
              <td>
                <div class="row">
                  <div
                    :class="{'col-md-8': item.product.using_variations, 'col-md-12': !item.product.using_variations}"
                  >
                    <form-autocomplete
                      data="products"
                      v-model="item.product"
                      :value="item.product"
                      :required="true"
                      @input="changedProduct(item)"
                      :formatSelectLabel="formatProductLabel"
                    />
                  </div>
                  <div class="col-md-4" v-if="item.product.using_variations">
                    <form-select
                      placeholder="Variação"
                      :options="item.product.variations"
                      v-model="item.variation_id"
                      :value="item.variation_id"
                      :required="item.product.using_variations"
                      :formatSelectLabel="formatVariationLabel"
                      @input="changedVariation(item)"
                    />
                  </div>
                </div>
              </td>
              <td>
                <form-input v-model="item.quantity" min="1" type="number" />
              </td>
              <td class="text-right">{{item.product && item.product.price | money}}</td>
              <td
                class="text-right"
              >{{item.product && item.quantity && item.quantity * item.product.price | money}}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm btn-link text-red"
                  title="Remover item"
                  @click="removeProduct(item)"
                  :disabled="orderCopy.products.length === 1"
                >
                  <i class="fe fe-trash"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="font-w600 text-right">Subtotal</td>
              <td class="text-right">{{subtotalValue | money}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td colspan="3" class="font-w600 text-right">Entrega</td>
              <td class="text-right">
                <form-money-input v-model="orderCopy.delivery_price" type="number" />
              </td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td colspan="3" class="font-w600 text-right">Acréscimo</td>
              <td class="text-right">
                <form-money-input v-model="orderCopy.addition" type="number" />
              </td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td colspan="3" class="font-w600 text-right">Desconto</td>
              <td class="text-right">
                <form-money-input v-model="orderCopy.discount" type="number" />
              </td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td colspan="3" class="font-weight-bold text-uppercase text-right">Total</td>
              <td class="font-weight-bold text-right">{{totalValue | money}}</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <button ref="form_submit" type="submit" style="display: none;"></button>
      </form>
    </div>
    <template v-slot:modal-footer="{ cancel }">
      <button
        type="button"
        class="btn btn-secondary"
        :disabled="isLoading || isSaving"
        @click="cancel()"
      >Cancelar</button>
      <button
        type="button"
        class="btn btn-primary"
        :class="{'btn-loading': isLoading || isSaving}"
        @click="save()"
      >Salvar</button>
    </template>
  </b-modal>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import formAutocomplete from "@/components/form-autocomplete";
import formSelect from "@/components/form-select";
import formMoneyInput from "@/components/form-money-input";
import formInput from "@/components/form-input";
import orderClient from "@/clients/order";
import { VBModal } from "bootstrap-vue";

export default {
  name: "order-edit-items",
  props: ["order"],
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  components: {
    formAutocomplete,
    formSelect,
    formMoneyInput,
    formInput,
  },
  watch: {
    subtotalValue() {
      this.setPaymentTypeAddition();
    },
  },
  data: function () {
    return {
      orderCopy: {
        products: []
      },
      isSaving: false,
      isLoading: false,
    };
  },
  computed: {
    id() {
      return `order-edit-items`;
    },
    subtotalValue() {
      return this.orderCopy.products.reduce(
        (p, c) => p + Number(c.product.price) * Number(c.quantity),
        0
      );
    },
    totalValue() {
      let value = 0;
      value += this.subtotalValue;

      value -= Number(this.orderCopy.discount);
      value += Number(this.orderCopy.addition);

      value += Number(this.orderCopy.delivery_price);

      if (value < 0) {
        value = 0;
      }

      return value;
    },
  },
  methods: {
    open() {
      this.$bvModal.show(this.id);
    },
    close() {
      this.$bvModal.hide(this.id);
    },
    reset() {
      this.isLoading = false;
      this.isSaving = false;
      this.orderCopy = { ...this.order };
      this.orderCopy.products = this.orderCopy.products.map((p) => {
        return {
          product: {
            ...p,
            price: Number(p.pivot.price),
          },
          quantity: Number(p.pivot.quantity),
          variation_id: p.pivot.variation_id,
        };
      });
    },
    save() {
      this.$refs.form_submit.click();
    },
    async onSave() {
      this.isSaving = true;
      await orderClient.update(this.orderCopy.id, {
        products: this.orderCopy.products,
        discount: this.orderCopy.discount,
        addition: this.orderCopy.addition,
      });
      this.$toast.success("Pedido salvo.");
      this.$emit("change");
      this.close();
    },
    addProduct() {
      const products = this.orderCopy.products || [];
      products.push({ product: { price: 0 }, quantity: 1 });
      this.$set(this.orderCopy, "products", products);
    },
    removeProduct(item) {
      const result = confirm(
        "Você tem certeza que deseja remover esse item do pedido?"
      );
      if (result) {
        const products = this.orderCopy.products || [];
        products.splice(products.indexOf(item), 1);
        this.$set(this.orderCopy, "products", products);
      }
    },
    changedProduct(item) {
      item.variation_id = null;
      item.quantity = 1;
    },
    formatProductLabel(option) {
      let quantity = option.available_quantity;
      if (option.using_variations) {
        quantity = option.variations.reduce(
          (val, curr) => val + curr.available_quantity,
          0
        );
      }
      return `${option.name} (${quantity} disp.)`;
    },
    formatVariationLabel(option) {
      return `${option.name} (${option.available_quantity} disp.)`;
    },
    changedVariation(item) {
      if (!item.variation_id) return;
      item.product.price = item.product.variations.find(
        (v) => v.id === item.variation_id
      ).price;
    },
    setPaymentTypeAddition() {
      this.orderCopy.addition = (
        (Number(this.subtotalValue) / 100) *
        Number(this.orderCopy.paymentType.rate_percentage)
      ).toFixed(2);
    },
  },
};
</script>

<style>
</style>
