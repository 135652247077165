import defaultClient from './default';

export default {
  getAll(query) {
    return defaultClient.get(`products/categories`, query);
  },
  getOne(id) {
    return defaultClient.get(`products/categories/${id}`);
  },
  create(data) {
    return defaultClient.post(`products/categories`, data);
  },
  update(id, data) {
    return defaultClient.put(`products/categories/${id}`, data);
  },
  remove(id) {
    return defaultClient.delete(`products/categories/${id}`);
  }

};