<template>
  <button
    v-if="this.nextStatus"
    :class="classes"
    @click="openConfirmModal"
    :disabled="disabled"
  >
    Marcar como {{ this.nextStatus.name }}
    <order-status-modal
      ref="order-next-modal"
      :order="order"
      :newStatus="this.nextStatus.key"
      @change="confirmChange"
    />
  </button>
</template>

<script>
import orderStatusModal from "@/components/order-status-modal";
import orderStatusUtil from "@/utils/order-status";
import { VBModal } from "bootstrap-vue";

export default {
  name: "order-next-status-btn",
  props: ["order"],
  directives: {
    "b-modal": VBModal,
  },
  components: {
    orderStatusModal,
  },
  data: function () {
    return {
      isLoading: false,
    };
  },
  computed: {
    nextStatus() {
      return orderStatusUtil.getNext(this.order.status);
    },
    classes() {
      return ["btn", `btn-${this.nextStatus.color}`];
    },
    disabled() {
      return this.nextStatus.key === "delivered" && !this.order.paid;
    },
  },
  methods: {
    openConfirmModal() {
      this.$refs["order-next-modal"].open();
    },
    confirmChange() {
      this.$emit("change");
    },
  },
};
</script>

<style>
</style>
