<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title" v-if="$route.params.id">Editar categoria</h3>
          <h3 class="card-title" v-else>Nova categoria</h3>
        </div>
        <center v-if="isLoading">Carregando...</center>
        <form @submit.prevent="save" v-else>
          <div class="card-body">
            <form-input label="Nome" v-model="product.name" :required="true" :autofocus="true" />
          </div>
          <div class="card-footer text-right">
            <div class="d-flex">
              <router-link class="btn btn-link" :to="{ name: 'product-categories' }">Cancelar</router-link>
              <form-save-btn class="ml-auto" label="Salvar" :isLoading="isSaving" />
            </div>
          </div>
        </form>
      </div>
      <small>* campos obrigatórios</small>
    </div>
  </div>
</template>

<script>
import formInput from "@/components/form-input";
import formSaveBtn from "@/components/form-save-btn";
import productCategoryClient from "@/clients/productCategory";

export default {
  components: {
    formInput,
    formSaveBtn,
  },
  data: function () {
    return {
      isLoading: true,
      isSaving: false,
      product: {
        price: 0,
      },
    };
  },
  methods: {
    async save() {
      this.isSaving = true;
      if (this.$route.params.id) {
        await productCategoryClient.update(this.$route.params.id, this.product);
      } else {
        await productCategoryClient.create(this.product);
      }
      this.$toast.success("Categoria salva.");
      this.$router.push({ name: "product-categories" });
    },
    async getData() {
      if (this.$route.params.id) {
        this.product = await productCategoryClient.getOne(
          this.$route.params.id
        );
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
</style>
