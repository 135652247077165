<template>
  <b-modal :id="id" title="Marcar como pago" header-close-content @show="reset">
    <form-money-input label="Valor pago" v-model="paidAmount" />
    <template v-slot:modal-footer="{ cancel }">
      <button
        type="button"
        class="btn btn-secondary"
        :disabled="isLoading"
        @click="cancel()"
      >Cancelar</button>
      <button
        type="button"
        class="btn btn-primary"
        :class="{'btn-loading': isLoading}"
        @click="save()"
      >Salvar</button>
    </template>
  </b-modal>
</template>

<script>
import formMoneyInput from "@/components/form-money-input";
import orderClient from "@/clients/order";
import { VBModal } from "bootstrap-vue";

export default {
  name: "order-set-paid-modal",
  props: ["order"],
  directives: {
    "b-modal": VBModal,
  },
  components: {
    formMoneyInput,
  },
  data: function () {
    return {
      isLoading: false,
      paidAmount: 0,
    };
  },
  computed: {
    id() {
      return `order-set-paid-modal`;
    },
  },
  methods: {
    open() {
      this.$bvModal.show(this.id);
    },
    close() {
      this.$bvModal.hide(this.id);
    },
    reset() {
      this.isLoading = false;
      this.paidAmount = this.order.total;
    },
    async save() {
      this.isLoading = true;
      // TODO: handle error
      await orderClient.update(this.order.id, {
        paid: true,
        paid_amount: this.paidAmount,
      });
      this.$toast.success("Anotações salvas.");
      this.$emit("change");
      this.close();
    },
  },
};
</script>

<style>
</style>
