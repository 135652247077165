<template>
  <div :class="{'form-group': !small}">
    <label v-if="label" class="form-label">
      {{label}}
      <small v-if="required">*</small>
    </label>
    <textarea
      v-if="textarea"
      class="form-control"
      :placeholder="_placeholder"
      :value="value"
      :rows="rows"
      :required="required"
      :disabled="disabled"
      ref="input"
      v-on:input="updateValue($event.target.value)"
    ></textarea>
    <input
      v-else
      :type="_type"
      class="form-control"
      :class="{'form-control-sm': small}"
      :placeholder="_placeholder"
      :value="value"
      :required="required"
      :disabled="disabled"
      ref="input"
      v-on:input="updateValue($event.target.value)"
      :min="min"
    />
    <small v-if="helpText" class="form-text text-muted">{{helpText}}</small>
  </div>
</template>

<script>
export default {
  name: "form-input",
  props: [
    "label",
    "placeholder",
    "value",
    "required",
    "textarea",
    "rows",
    "small",
    "type",
    "min",
    "helpText",
    "disabled",
    "autofocus"
  ],
  computed: {
    _type() {
      return this.type || "text";
    },
    _placeholder() {
      return this.placeholder || this.label;
    },
  },
  mounted() {
    if(this.autofocus) {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style>
</style>
