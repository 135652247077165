<template>
  <div :class="{'form-group': !small}">
    <label v-if="label" class="form-label">
      {{label}}
      <small v-if="required">*</small>
    </label>
    <input
      ref="input"
      type="text"
      class="form-control"
      :class="{'form-control-sm': small}"
      :placeholder="label"
      :value="value"
      :required="required"
      v-on:input="updateValue($event.target.value)"
      v-money="config"
    />
  </div>
</template>

<script>
import { VMoney } from "v-money";
import numberUtil from "@/utils/number";

export default {
  name: "form-money-input",
  directives: { money: VMoney },
  props: ["label", "value", "required", "small", "type", "autofocus"],
  data() {
    return {
      config: {
        decimal: ",",
        thousands: ".",
        prefix:
          this.type && (this.type !== "number" || this.type !== "percentage")
            ? ""
            : "R$ ",
        suffix: this.type === "percentage" ? " %" : "",
        precision: 2,
      },
    };
  },
  mounted() {
    if(this.autofocus) {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", numberUtil.unformat(value));
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style>
</style>
