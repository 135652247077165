<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title" v-if="$route.params.id">Editar forma de entrega</h3>
          <h3 class="card-title" v-else>Nova forma de entrega</h3>
        </div>
        <center v-if="isLoading">Carregando...</center>
        <form @submit.prevent="save" v-else>
          <div class="card-body">
            <form-input label="Nome" v-model="data.name" :required="true" :autofocus="true" />
            <form-money-input label="Taxa" v-model="data.price" :required="true" />
            <div class="form-group">
              <label class="custom-switch">
                <input
                  type="checkbox"
                  name="custom-switch-checkbox"
                  class="custom-switch-input"
                  v-model="data.is_pickup"
                />
                <span class="custom-switch-indicator"></span>
                <span class="custom-switch-description">Opção de retirada</span>
              </label>
            </div>
            <form-input
              label="Instruções para retirada"
              :textarea="true"
              rows="5"
              v-if="data.is_pickup"
              v-model="data.pickup_information"
            />
            <div class="form-group">
              <label class="custom-switch">
                <input
                  type="checkbox"
                  name="custom-switch-checkbox"
                  class="custom-switch-input"
                  v-model="data.active"
                />
                <span class="custom-switch-indicator"></span>
                <span class="custom-switch-description">Habilitado</span>
              </label>
            </div>
          </div>
          <div class="card-footer text-right">
            <div class="d-flex">
              <router-link class="btn btn-link" :to="{ name: 'delivery-types' }">Cancelar</router-link>
              <form-save-btn class="ml-auto" label="Salvar" :isLoading="isSaving" />
            </div>
          </div>
        </form>
      </div>
      <small>* campos obrigatórios</small>
    </div>
  </div>
</template>

<script>
import formInput from "@/components/form-input";
import formMoneyInput from "@/components/form-money-input";
import formSaveBtn from "@/components/form-save-btn";
import deliveryTypeClient from "@/clients/deliveryType";

export default {
  components: {
    formInput,
    formMoneyInput,
    formSaveBtn,
  },
  data: function () {
    return {
      isLoading: true,
      isSaving: false,
      data: {
        name: "",
        information: "",
        rate_percentage: 0,
        active: true,
      },
    };
  },
  methods: {
    async save() {
      this.isSaving = true;
      if (this.$route.params.id) {
        await deliveryTypeClient.update(this.$route.params.id, this.data);
      } else {
        await deliveryTypeClient.create(this.data);
      }
      this.$toast.success("Forma de entrega salvo.");
      this.$router.push({ name: "delivery-types" });
    },
    async getData() {
      if (this.$route.params.id) {
        this.data = await deliveryTypeClient.getOne(this.$route.params.id);
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
</style>
