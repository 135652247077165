import defaultClient from './default';

export default {
  getAll(query) {
    return defaultClient.get(`payment-types`, query);
  },
  getOne(id) {
    return defaultClient.get(`payment-types/${id}`);
  },
  create(data) {
    return defaultClient.post(`payment-types`, data);
  },
  update(id, data) {
    return defaultClient.put(`payment-types/${id}`, data);
  },
  remove(id) {
      return defaultClient.delete(`payment-types/${id}`);
  }

};