import defaultClient from './default';

export default {
  getAll(query) {
    return defaultClient.get(`products/disponibilities`, query);
  },
  getOne(id) {
    return defaultClient.get(`products/disponibilities/${id}`);
  },
  create(data) {
    return defaultClient.post(`products/disponibilities`, data);
  },
  update(id, data) {
    return defaultClient.put(`products/disponibilities/${id}`, data);
  },
  remove(id) {
    return defaultClient.delete(`products/disponibilities/${id}`);
  },
  getAutomation() {
    return defaultClient.get(`products/disponibilities/automation`);
  },
  updateAutomation(data) {
    return defaultClient.post(`products/disponibilities/automation`, data);
  },
};