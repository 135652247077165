<template>
  <div class="page" v-if="!isLogin">
    <div class="flex-fill">
      <div class="header py-4">
        <div class="container">
          <div class="d-flex">
            <div class="header-brand">
              <img src="./assets/logo.png" class="header-brand-img" alt="logo" />
              <span>Delivery - Mistura Fina</span>
            </div>
            <div class="d-flex order-lg-2 ml-auto">
              <!-- <div class="dropdown d-none d-md-flex">
                <a class="nav-link icon" data-toggle="dropdown">
                  <i class="fe fe-bell"></i>
                  <span class="nav-unread"></span>
                </a>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <a href="javascript:;" class="dropdown-item d-flex">
                    <span
                      class="avatar mr-3 align-self-center"
                      style="background-image: url(demo/faces/male/41.jpg)"
                    ></span>
                    <div>
                      <strong>Nathan</strong> pushed new commit: Fix page load performance issue.
                      <div class="small text-muted">10 minutes ago</div>
                    </div>
                  </a>
                  <a href="javascript:;" class="dropdown-item d-flex">
                    <span
                      class="avatar mr-3 align-self-center"
                      style="background-image: url(demo/faces/female/1.jpg)"
                    ></span>
                    <div>
                      <strong>Alice</strong> started new task: Tabler UI design.
                      <div class="small text-muted">1 hour ago</div>
                    </div>
                  </a>
                  <a href="javascript:;" class="dropdown-item d-flex">
                    <span
                      class="avatar mr-3 align-self-center"
                      style="background-image: url(demo/faces/female/18.jpg)"
                    ></span>
                    <div>
                      <strong>Rose</strong> deployed new version of NodeJS REST Api V3
                      <div class="small text-muted">2 hours ago</div>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a href="javascript:;" class="dropdown-item text-center">Mark all as read</a>
                </div>
              </div>-->
              <div class="dropdown">
                <a href="javascript:;" class="nav-link pr-0 leading-none" data-toggle="dropdown">
                  <span class="avatar">{{user.name[0].toUpperCase()}}</span>
                  <span class="ml-2 d-none d-lg-block">
                    <span class="text-default">{{user.name}}</span>
                    <small class="text-muted d-block mt-1">
                      Opções
                      <i class="fe fe-chevron-down user-menu-icon"></i>
                    </small>
                  </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                  <router-link class="dropdown-item" :to="{ name: 'profile' }">
                    <i class="dropdown-icon fe fe-user"></i> Perfil
                  </router-link>
                  <!-- <a class="dropdown-item" href="javascript:;">
                    <i class="dropdown-icon fe fe-settings"></i> Configurações
                  </a>-->
                  <div class="dropdown-divider"></div>
                  <router-link class="dropdown-item" :to="{ name: 'users' }">
                    <i class="dropdown-icon fe fe-users"></i> Usuários
                  </router-link>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="javascript:;" @click="logout()">
                    <i class="dropdown-icon fe fe-log-out"></i> Sair
                  </a>
                </div>
              </div>
            </div>
            <a
              href="javascript:;"
              class="header-toggler d-lg-none ml-3 ml-lg-0"
              data-toggle="collapse"
              data-target="#headerMenuCollapse"
            >
              <span class="header-toggler-icon"></span>
            </a>
          </div>
        </div>
      </div>
      <div class="header collapse d-lg-flex p-0" id="headerMenuCollapse">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg order-lg-first">
              <ul class="nav nav-tabs border-0 flex-column flex-lg-row">
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ name: 'home' }">
                    <i class="fe fe-home"></i> Home
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ name: 'calendar' }">
                    <i class="fe fe-calendar"></i> Calendário
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ name: 'orders' }">
                    <i class="fe fe-shopping-bag"></i> Pedidos
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ name: 'products' }">
                    <i class="fe fe-package"></i> Produtos
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ name: 'customers' }">
                    <i class="fe fe-users"></i> Clientes
                  </router-link>
                </li>
                <li class="nav-item dropdown">
                  <a
                    href="javascript:;"
                    class="nav-link"
                    data-toggle="dropdown"
                    :class="{'active': isDropdownPath}"
                  >
                    <i class="fe fe-list"></i> Cadastros
                  </a>
                  <div class="dropdown-menu dropdown-menu-arrow">
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'product-categories' }"
                    >Categoria de produtos</router-link>
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'product-disponibilities' }"
                    >Disponibilidade de produtos</router-link>
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'payment-types' }"
                    >Formas de pagamento</router-link>
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'delivery-types' }"
                    >Formas de entrega</router-link>
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'delivery-periods' }"
                    >Períodos de entrega</router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="my-3 my-md-5">
        <div class="container">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="container">
        <div class="row align-items-center flex-row-reverse">
          <div class="col-auto ml-lg-auto">
            <div class="row align-items-center">
              <div class="col-auto">
                <ul class="list-inline list-inline-dots mb-0">
                  <li class="list-inline-item">
                    <a href="mailto:contato@felipe.im">Suporte</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-auto mt-3 mt-lg-0 text-center">
            &copy; 2020
            Felipe Valtl de Mello - Todos os direitos reservados.
            <small>
              Theme by
              <a href="https://tabler.io/" target="_blank">Tabler</a>
            </small>
          </div>
        </div>
      </div>
    </footer>
  </div>
  <div v-else class="page">
    <div class="page-single">
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import "tabler-ui/dist/assets/css/dashboard.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue";
import "@fortawesome/fontawesome-free/css/all.css";
import authService from "@/services/auth";

export default {
  name: "App",
  computed: {
    user() {
      return this.isLogin ? {} : authService.getUser();
    },
    isLogin() {
      return this.$route.path === "/login";
    },
    isDropdownPath: {
      get() {
        return this.$route.path.indexOf("/product-categories") === 0;
      }
    }
  },
  methods: {
    logout() {
      authService.remove();
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style>
.dropdown-item.active i {
  color: #ffffff;
}
.user-menu-icon {
  vertical-align: middle;
}
</style>
