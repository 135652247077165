<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Pedidos</h3>
        </div>
        <b-tabs class="orders-tabs" lazy>
          <b-tab title="Em aberto" active>
            <template v-slot:title>
              Em aberto
              <span class="badge badge-pill badge-primary">{{ordersCount.open || 0}}</span>
            </template>
            <data-table
              resource="orders"
              customPath="orders/open"
              :columns="tableColumns"
              :actions="tableActions"
            />
          </b-tab>
          <b-tab title="Todos">
            <template v-slot:title>
              Todos
              <span class="badge badge-pill badge-primary">{{ordersCount.all || 0}}</span>
            </template>
            <data-table resource="orders" :columns="tableColumns" :actions="tableActions" />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import dataTable from "@/components/datatable";
import orderClient from "@/clients/order";

export default {
  components: {
    BTabs,
    BTab,
    dataTable,
  },
  data: function () {
    return {
      ordersCount: {},
      tableColumns: [
        { column: "code", name: "Código", route: "orders-show" },
        { column: "user.name", name: "Cliente" },
        { column: "deliveryType.name", name: "Tipo" },
        { column: "delivery_date", name: "Entrega", format: "date" },
        { column: "deliveryPeriod.name", name: "Período" },
        { column: "total", name: "Total", format: "money" },
        { column: "paid", name: "Pago", format: "order-paid-tag" },
        { column: "status", name: "Status", format: "order-status-tag" },
      ],
      tableActions: ["show"],
    };
  },
  methods: {
    async getData() {
      this.ordersCount = await orderClient.getOrdersCount();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.orders-tabs .nav.nav-tabs {
  margin: 0;
  margin-top: 6px;
}
</style>
