<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Novo cliente</h3>
        </div>
        <center v-if="isLoading">Carregando...</center>
        <form @submit.prevent="save" v-else>
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <form-input
                  ref="new_customer_name"
                  label="Nome"
                  placeholder="Nome completo"
                  v-model="data.name"
                  :required="true"
                />
              </div>
              <div class="col-md-4">
                <form-masked-input
                  label="Celular"
                  v-model="data.phone"
                  mask="(##) #####-####"
                  :required="true"
                />
              </div>
            </div>
            <form-checkbox label="Cadastrar endereço" v-model="createAddress" />
            <div class="row" v-show="createAddress">
              <div class="col-md-2">
                <form-input label="Nome do endereço" v-model="data.address.name" />
              </div>
              <div class="col-md-2">
                <form-masked-input
                  label="CEP"
                  v-model="data.address.postal_code"
                  mask="##.###-###"
                  @blur="getPostalCodeAddress()"
                />
              </div>
              <div class="col-md-6">
                <form-input label="Rua" v-model="data.address.address" :required="createAddress" />
              </div>
              <div class="col-md-2">
                <form-input
                  ref="address_number"
                  label="Número"
                  v-model="data.address.number"
                  :required="createAddress"
                />
              </div>
              <div class="col-md-3">
                <form-input label="Complemento" v-model="data.address.complement" />
              </div>
              <div class="col-md-3">
                <form-input label="Bairro" v-model="data.address.neighborhood" :required="createAddress" />
              </div>
              <div class="col-md-3">
                <form-input label="Cidade" v-model="data.address.city" :required="createAddress" />
              </div>
              <div class="col-md-3">
                <form-input label="Estado" v-model="data.address.state" :required="createAddress" />
              </div>
            </div>
            <form-input label="Anotações" :textarea="true" rows="5" v-model="data.annotations" />
          </div>
          <div class="card-footer text-right">
            <div class="d-flex">
              <router-link class="btn btn-link" :to="{ name: 'delivery-types' }">Cancelar</router-link>
              <form-save-btn class="ml-auto" label="Salvar" :isLoading="isSaving" />
            </div>
          </div>
        </form>
      </div>
      <small>* campos obrigatórios</small>
    </div>
  </div>
</template>

<script>
import formInput from "@/components/form-input";
import formMaskedInput from "@/components/form-masked-input";
import formCheckbox from "@/components/form-checkbox";
import formSaveBtn from "@/components/form-save-btn";
import customerClient from "@/clients/customer";
import postalCodeClient from "@/clients/postalCode";

export default {
  components: {
    formInput,
    formMaskedInput,
    formCheckbox,
    formSaveBtn,
  },
  data: function () {
    return {
      isLoading: false,
      isSaving: false,
      createAddress: true,
      data: {
        name: "",
        phone: "",
        annotations: "",
        address: {
          name: "",
          postal_code: "",
          address: "",
          number: "",
          complement: "",
          neighborhood: "",
          city: "Joinville",
          state: "SC",
        },
      },
    };
  },
  methods: {
    async getPostalCodeAddress() {
      let postalCode = (this.data.address || {}).postal_code;
      if (postalCode && postalCode.length === 10) {
        this.data.address.address = "...";
        const result = await postalCodeClient.get(postalCode);
        this.data.address.address = result.logradouro;
        this.data.address.state = result.uf;
        this.data.address.city = result.localidade;
        this.data.address.neighborhood = result.bairro;
        this.$refs.address_number.focus();
      }
    },
    async save() {
      this.isSaving = true;
      const saveData = {
        ...this.data
      };
      if(!this.createAddress) {
        delete saveData.address;
      }
      try {
        await customerClient.create(saveData);
        this.$toast.success("Cliente salvo.");
        this.$router.push({
          name: "customers",
        });
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
  },
};
</script>

<style>
</style>
