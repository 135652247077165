<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title" v-if="$route.params.id">Editar usuário</h3>
          <h3 class="card-title" v-else>Novo usuário</h3>
        </div>
        <center v-if="isLoading">Carregando...</center>
        <form @submit.prevent="save" v-else>
          <div class="card-body">
            <form-input label="Nome" v-model="user.name" :required="true" :autofocus="true" />
            <form-input label="E-mail" v-model="user.email" type="email" :required="true" />
            <form-masked-input
              label="Celular"
              v-model="user.phone"
              mask="(##) #####-####"
              :required="true"
            />
            <form-input
              label="Senha"
              type="password"
              :helpText="$route.params.id ? 'Apenas preencha se deseja alterar sua senha.' : ''"
              :required="!$route.params.id"
              v-model="user.password"
            />
          </div>
          <div class="card-footer text-right">
            <div class="d-flex">
              <router-link class="btn btn-link" :to="{ name: 'users' }">Cancelar</router-link>
              <form-save-btn class="ml-auto" label="Salvar" :isLoading="isSaving" />
            </div>
          </div>
        </form>
      </div>
      <small>* campos obrigatórios</small>
    </div>
  </div>
</template>

<script>
import formInput from "@/components/form-input";
import formMaskedInput from "@/components/form-masked-input";
import formSaveBtn from "@/components/form-save-btn";
import userClient from "@/clients/user";

export default {
  components: {
    formInput,
    formMaskedInput,
    formSaveBtn,
  },
  data: function () {
    return {
      isLoading: true,
      isSaving: false,
      user: {
        price: 0,
      },
    };
  },
  methods: {
    async save() {
      this.isSaving = true;
      if (this.$route.params.id) {
        await userClient.update(this.$route.params.id, this.user);
      } else {
        await userClient.create(this.user);
      }
      this.$toast.success("Usuário salvo.");
      this.$router.push({ name: "users" });
    },
    async getData() {
      if (this.$route.params.id) {
        this.user = await userClient.getOne(this.$route.params.id);
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
</style>
