import Index from '@/routes/delivery-periods';
import Form from '@/routes/delivery-periods/form';

export default [{
  path: '/delivery-periods',
  name: 'delivery-periods',
  component: Index
}, {
  path: '/delivery-periods/new',
  name: 'delivery-periods-new',
  component: Form
}, {
  path: '/delivery-periods/:id',
  name: 'delivery-periods-edit',
  component: Form
}];