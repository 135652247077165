export default {
  async get(postalCode) {
    postalCode = postalCode.replace(/\D/gi, '');
    const result = await fetch(`https://viacep.com.br/ws/${postalCode}/json/`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    });
    return result.json();
  }
};