<template>
  <span :class="classes">{{this.paid ? 'Pago' : 'Não pago'}}</span>
</template>

<script>
export default {
  name: "order-paid-tag",
  props: ["paid"],
  computed: {
    classes() {
      return ["tag", `tag-${this.paid ? 'success' : 'warning'}`];
    }
  }
};
</script>

<style>
</style>
