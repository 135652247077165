<template>
  <div class="autocomplete" :class="{'form-group': !small}">
    <label v-if="label" class="form-label">
      {{label}}
      <small v-if="required">*</small>
    </label>
    <input
      ref="input"
      type="text"
      class="form-control"
      :class="{'form-control-sm': small}"
      placeholder="Pesquisar..."
      v-model="search"
      :required="required"
      @input="onChange"
      @focus="onFocus"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      @keydown.escape="onEscape"
    />
    <ul v-show="isOpen" class="dropdown-menu show">
      <li class="dropdown-item autocomplete-loading" v-if="isLoading">Carregando...</li>
      <li
        v-else
        v-for="(option, i) in options"
        :key="i"
        @click="setResult(option)"
        class="dropdown-item autocomplete-item"
        :class="{ 'active': i === arrowCounter }"
      >{{ formatSelectLabel ? formatSelectLabel(option) : option.name }}</li>
      <li class="dropdown-item autocomplete-no-result" v-if="!options.length">Nenhum resultado.</li>
    </ul>
  </div>
</template>

<script>
import client from "@/clients/default";

export default {
  name: "form-autocomplete",
  props: [
    "label",
    "value",
    "data",
    "noEmptyOption",
    "small",
    "required",
    "formatSelectLabel",
    "autofocus",
  ],
  data: function () {
    return {
      isLoading: true,
      isOpen: false,
      search: "",
      result: null,
      options: [],
      arrowCounter: -1,
    };
  },
  methods: {
    onChange() {
      this.isOpen = true;
      this.getData();
      this.$emit("search", this.search);
    },
    async getData() {
      this.isLoading = true;
      const query = client.getQueryParams({
        table: this.data,
        search: this.search,
      });
      this.options = await client.get(`selectdata${query}`);
      this.isLoading = false;
    },
    setResult(result, emitEvent = true) {
      if (!result) return;
      this.search = result.name;
      this.result = result;
      this.isOpen = false;
      this.arrowCounter = -1;
      if (emitEvent) {
        this.$emit("input", this.result);
      }
    },
    onFocus() {
      this.isOpen = true;
    },
    onArrowDown() {
      if (this.arrowCounter < this.options.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter(evt) {
      if (this.isOpen) {
        this.setResult(this.options[this.arrowCounter]);
        this.arrowCounter = -1;
        evt.preventDefault();
      }
    },
    onEscape() {
      this.isOpen = false;
      if (this.result && this.result.name) {
        this.search = this.result.name;
      } else {
        this.search = "";
      }
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.onEscape();
        this.arrowCounter = -1;
      }
    },
    focus() {
      this.$refs.input.focus();
    },
  },
  async mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }
    await this.getData();
    if (this.value) {
      this.setResult(this.value, false);
    }
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.autocomplete {
  position: relative;
}
.autocomplete-item:hover {
  cursor: pointer;
}
</style>
