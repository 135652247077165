export default {
  pending_confirmation: {
    key: 'pending_confirmation',
    name: 'Pendente confirmação',
    color: 'warning',
    next: 'accepted'
  },
  accepted: {
    key: 'accepted',
    name: 'Aceito',
    color: 'info',
    next: 'scheduled'
  },
  scheduled: {
    key: 'scheduled',
    name: 'Agendado',
    color: 'primary',
    next: 'delivered'
  },
  delivered: {
    key: 'delivered',
    name: 'Entregue',
    color: 'success',
    next: null
  },
  canceled: {
    key: 'canceled',
    name: 'Cancelado',
    color: 'secondary',
    next: null
  },

  get(status) {
    return this[status];
  },
  getNext(status) {
    return this[this.get(status).next];
  }
}