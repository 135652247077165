<template>
  <div class="form-group">
    <label v-if="label" class="form-label">
      {{label}}
      <small v-if="required">*</small>
    </label>
    <select class="form-control" @change="change" :required="required">
      <option v-if="isLoading">Carregando...</option>
      <option
        v-else
        v-for="(option, index) in options"
        :key="index"
        :value="option.id"
        :selected="selectedOption(option)"
      >{{option.name}}</option>
    </select>
    <small v-if="helpText" class="form-text text-muted">{{helpText}}</small>
  </div>
</template>

<script>
import client from "@/clients/default";

export default {
  name: "form-remote-select",
  props: [
    "label",
    "value",
    "data",
    "additionalFields",
    "getFullModel",
    "noEmptyOption",
    "filters",
    "helpText",
    "required",
  ],
  data: function () {
    return {
      isLoading: true,
      options: [],
    };
  },
  watch: {
    filters: {
      handler(a, b) {
        if (JSON.stringify(a) !== JSON.stringify(b)) {
          this.getData();
        }
      },
      deep: true,
    },
  },
  methods: {
    selectedOption(option) {
      if (this.value) {
        return option.id == (this.getFullModel ? this.value.id : this.value);
      }
      return false;
    },
    change(e) {
      const selectedId = e.target.value;
      const model = this.options.find((o) => o.id == selectedId);
      this.$emit("input", this.getFullModel ? model : selectedId);
    },
    async getData() {
      const query = client.getQueryParams({
        table: this.data,
        additionalFields: this.additionalFields || [],
        filters: this.filters ? JSON.stringify(this.filters) : "",
      });
      this.options = await client.get(`selectdata${query}`);
      if (!this.noEmptyOption) {
        this.options.unshift({ id: null, name: "" });
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
</style>
