<template>
  <b-modal :id="id" title="Confirmação" header-close-content @show="reset">
    <p>
      Você tem certeza que deseja alterar o status do pedido
      <strong>{{this.order.code}}</strong> para
      <strong>{{this.statusData.name}}</strong>?
    </p>
    <template v-slot:modal-footer="{ cancel }">
      <button type="button" class="btn btn-secondary" :disabled="isLoading" @click="cancel()">Não</button>
      <button
        type="button"
        class="btn btn-primary"
        :class="{'btn-loading': isLoading}"
        @click="confirmChange()"
      >Sim</button>
    </template>
  </b-modal>
</template>

<script>
import orderClient from "@/clients/order";
import orderStatusUtil from "@/utils/order-status";
import { VBModal } from "bootstrap-vue";

export default {
  name: "order-status-modal",
  props: ["order", "newStatus"],
  directives: {
    "b-modal": VBModal
  },
  data: function() {
    return {
      isLoading: false
    };
  },
  computed: {
    statusData() {
      return orderStatusUtil.get(this.newStatus);
    },
    id() {
      return `confirm-modal-${this.statusData.key}`;
    }
  },
  methods: {
    open() {
      this.$bvModal.show(this.id);
    },
    close() {
      this.$bvModal.hide(this.id);
    },
    reset() {
      this.isLoading = false;
    },
    async confirmChange() {
      this.isLoading = true;
      // TODO: handle error
      await orderClient.update(this.order.id, {
        status: this.newStatus
      });
      this.$toast.success("Status alterado.");
      this.$emit("change");
      this.close();
    }
  }
};
</script>

<style>
</style>
