import Index from '@/routes/customers';
import Show from '@/routes/customers/show';
import NewForm from '@/routes/customers/new-form';
import EditForm from '@/routes/customers/edit-form';

export default [{
  path: '/customers',
  name: 'customers',
  component: Index
}, {
  path: '/customers/new',
  name: 'customers-new',
  component: NewForm
}, {
  path: '/customers/:id',
  name: 'customers-show',
  component: Show
}, {
  path: '/customers/:id/edit',
  name: 'customers-edit',
  component: EditForm
}];