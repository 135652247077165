<template>
  <span :class="classes">{{this.statusData.name}}</span>
</template>

<script>
import orderStatusUtil from "@/utils/order-status";

export default {
  name: "order-status-tag",
  props: ["status"],
  computed: {
    statusData() {
      return orderStatusUtil.get(this.status);
    },
    classes() {
      return ["tag", `tag-${this.statusData.color}`];
    }
  }
};
</script>

<style>
</style>
