import defaultClient from './default';

export default {
  getAll(query) {
    return defaultClient.get(`users`, query);
  },
  getOne(id) {
    return defaultClient.get(`users/${id}`);
  },
  create(data) {
    return defaultClient.post(`users`, data);
  },
  update(id, data) {
    return defaultClient.put(`users/${id}`, data);
  },
  remove(id) {
    return defaultClient.delete(`users/${id}`);
  }
};