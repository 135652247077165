import Vue from 'vue';
import moment from 'moment';
import client from "@/clients/default";

export default {
  set(result) {
    localStorage.setItem('token', result.token);
    localStorage.setItem('token_expires', result.expires);
    localStorage.setItem('user', JSON.stringify(result.user));
    Vue.prototype.$_token = result.token;
  },

  getToken() {
    return localStorage.getItem('token');
  },

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  },

  remove() {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expires');
    localStorage.removeItem('user');
    Vue.prototype.$_token = null;
  },

  startRefresh() {
    setInterval(async () => {
      const expires = localStorage.getItem('token_expires');
      if (expires) {
        if (moment(expires).diff(moment(), 'minutes') <= 15) {
          const result = await client.get('auth/refresh');
          this.set(result);
        }
      }
    }, 10 * 60 * 1000); // 10 minutes
  },
}