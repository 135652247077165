<template>
  <div class="form-group">
    <label class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        :checked="checked"
        @change="updateValue($event.target.checked)"
      />
      <span class="custom-control-label">{{label}}</span>
    </label>
  </div>
</template>

<script>

export default {
  name: "form-checkbox",
  props: {
    checked: Boolean,
    label: String
  },
  model: {
    prop: 'checked',
    event: 'change'
  },
  methods: {
    updateValue: function(value) {
      this.$emit("change", value);
    }
  }
};
</script>

<style>
</style>
