import Index from '@/routes/payment-types';
import Form from '@/routes/payment-types/form';

export default [{
  path: '/payment-types',
  name: 'payment-types',
  component: Index
}, {
  path: '/payment-types/new',
  name: 'payment-types-new',
  component: Form
}, {
  path: '/payment-types/:id',
  name: 'payment-types-edit',
  component: Form
}];