<template>
  <center v-if="isLoading">Carregando...</center>
  <div v-else>
    <div class="row mb-5">
      <div class="col-md-12">
        <div class="order-header">
          <h1 class="page-title">Pedido: {{ order.code }}</h1>
          <div class="tag-container ml-3">
            <order-paid-tag :paid="order.paid" />
            <order-status-tag class="ml-3" :status="order.status" />
          </div>
          <div class="order-options d-print-none">
            <div class="btn-list">
              <div class="dropdown">
                <button
                  type="button"
                  class="btn btn-secondary dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Opções
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" href="javascript:window.print();">
                    <i class="fe fe-printer mr-2"></i> Imprimir
                  </a>
                  <a
                    class="dropdown-item"
                    href="javascript:;"
                    @click="openHistory()"
                  >
                    <i class="fe fe-eye mr-2"></i> Histórico
                  </a>
                  <!-- <a class="dropdown-item" href="javascript:;" v-if="ableToEdit">
                    <i class="fe fe-edit mr-2"></i> Editar pedido
                  </a>-->
                  <div class="dropdown-divider" v-if="ableToEdit"></div>
                  <a
                    class="dropdown-item"
                    href="javascript:;"
                    v-if="ableToEdit && order.paid"
                    @click="setUnpaid()"
                  >
                    <i class="fe fe-slash mr-2"></i> Marcar como não pago
                  </a>
                  <a
                    class="dropdown-item"
                    href="javascript:;"
                    v-if="ableToEdit"
                    @click="editDiscountAddition()"
                  >
                    <i class="fe fe-percent mr-2"></i> Desconto / Acréscimo
                  </a>
                  <div class="dropdown-divider" v-if="ableToEdit"></div>
                  <a
                    class="dropdown-item text-danger"
                    href="javascript:;"
                    v-if="ableToEdit"
                    @click="cancelOrder()"
                  >
                    <i class="fe fe-x mr-2"></i> Cancelar pedido
                  </a>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-success"
                v-if="ableToEdit && !order.paid"
                @click="setPaid()"
              >
                Marcar como Pago
              </button>
              <order-next-status-btn :order="order" @change="changedStatus" />
            </div>
          </div>
        </div>
        <small>{{ order.created_at | datetime }}</small>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Itens</h3>
            <div class="card-options">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                v-if="ableToEdit"
                @click="editItems()"
              >
                Editar
              </button>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <table
              class="table table-hover table-outline table-vcenter text-nowrap card-table"
            >
              <tbody>
                <tr>
                  <th>Produto</th>
                  <th class="text-center" style="width: 1%">Qtde.</th>
                  <th class="text-right" style="width: 1%">Valor</th>
                  <th class="text-right" style="width: 1%">Total</th>
                </tr>
                <tr v-for="(item, index) in order.products" :key="index">
                  <td>
                    <p class="font-w600 mb-1">{{ item.pivot.name }}</p>
                    <div class="text-muted">{{ item.pivot.variation }}</div>
                  </td>
                  <td class="text-center">
                    {{ item.pivot.quantity | number }}
                  </td>
                  <td class="text-right">{{ item.pivot.price | money }}</td>
                  <td class="text-right">
                    {{ item.pivot.total_price | money }}
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="font-w600 text-right">Subtotal</td>
                  <td class="text-right">{{ order.subtotal | money }}</td>
                </tr>
                <tr>
                  <td colspan="3" class="font-w600 text-right">Entrega</td>
                  <td class="text-right">{{ order.delivery_price | money }}</td>
                </tr>
                <tr v-if="Number(order.addition)">
                  <td colspan="3" class="font-w600 text-right">Acréscimo</td>
                  <td class="text-right">{{ order.addition | money }}</td>
                </tr>
                <tr v-if="Number(order.discount)">
                  <td colspan="3" class="font-w600 text-right">Desconto</td>
                  <td class="text-right">- {{ order.discount | money }}</td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="font-weight-bold text-uppercase text-right"
                  >
                    Total
                  </td>
                  <td class="font-weight-bold text-right">
                    {{ order.total | money }}
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="text-right">Pago</td>
                  <td class="text-right">{{ order.paid_amount | money }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card" v-if="!order.is_manual">
          <div class="card-header">
            <h3 class="card-title">Comentários do cliente</h3>
          </div>
          <div class="card-body">
            <p class="pre-formatted" v-if="order.comments">
              {{ order.comments }}
            </p>
            <p v-else>
              <i>Nenhum comentário deixado pelo cliente.</i>
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">
              Anotações
              <small class="card-title-small">(privado)</small>
            </h3>
            <div class="card-options">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                @click="editAnnotations()"
              >
                Editar
              </button>
            </div>
          </div>
          <div class="card-body">
            <p class="pre-formatted" v-if="order.annotations">
              {{ order.annotations }}
            </p>
            <p v-else>
              <i>Nenhuma anotação.</i>
            </p>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <ul class="list-unstyled list-separated">
              <li class="list-separated-item pb-2">
                <h6 class="text-muted-dark">Forma de entrega</h6>
                <div>
                  {{ order.deliveryType.name }}&nbsp;
                  <a
                    href="javascript:;"
                    class="small d-print-none"
                    v-if="ableToEdit"
                    @click="editDeliveryType()"
                    >Editar</a
                  >
                </div>
              </li>
              <li class="list-separated-item pb-2">
                <h6 class="text-muted-dark">Data de Entrega</h6>
                <div>
                  <span v-if="order.delivery_date"
                    >{{ order.delivery_date | date }} -
                    {{
                      order.deliveryPeriod && order.deliveryPeriod.name
                    }}</span
                  >
                  <i v-else>Sem data definida.</i>&nbsp;
                  <a
                    href="javascript:;"
                    class="small d-print-none"
                    v-if="ableToEdit"
                    @click="editDeliveryDate()"
                    >Editar</a
                  >
                </div>
              </li>
              <li class="list-separated-item pb-2">
                <h6 class="text-muted-dark">Forma de pagamento</h6>
                <div>
                  {{ order.paymentType.name }}&nbsp;
                  <a
                    href="javascript:;"
                    class="small d-print-none"
                    v-if="ableToEdit"
                    @click="editPaymentType()"
                    >Editar</a
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Cliente</h3>
            <div class="card-options">
              <div class="btn-group">
                <a
                  :href="`https://api.whatsapp.com/send?phone=55${order.user.phone}`"
                  target="_blank"
                  class="btn btn-success btn-sm"
                  v-b-tooltip.hover
                  title="Enviar mensagem whatsapp"
                >
                  <i class="fab fa-whatsapp"></i>
                </a>
                <router-link
                  :to="{
                    name: 'customers-show',
                    params: { id: order.user.id },
                  }"
                  v-b-tooltip.hover
                  title="Abrir cadastro do cliente"
                  class="btn btn-primary btn-sm"
                >
                  <i class="fe fe-external-link"></i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <ul class="list-unstyled list-separated">
              <li class="list-separated-item pb-2">
                <h6 class="text-muted-dark">Nome</h6>
                <div>{{ order.user.name }}</div>
              </li>
              <li class="list-separated-item pb-2">
                <h6 class="text-muted-dark">E-mail</h6>
                <div>
                  <a :href="`mailto:${order.user.email}`">{{
                    order.user.email
                  }}</a>
                </div>
                <!-- // TODO: mostrar se está verificado -->
              </li>
              <li class="list-separated-item pb-2">
                <h6 class="text-muted-dark">Telefone</h6>
                <div>{{ order.user.phone | maskphone }}</div>
                <!-- // TODO: mostrar se está verificado -->
              </li>
              <li class="list-separated-item pb-2" v-if="order.userAddress">
                <h6 class="text-muted-dark">Endereço</h6>
                <div>
                  <p>
                    {{ order.userAddress.address }},
                    {{ order.userAddress.number
                    }}{{
                      order.userAddress.complement &&
                      ` - ${order.userAddress.complement}`
                    }}
                    <br />
                    {{ order.userAddress.neighborhood }} -
                    {{ order.userAddress.city }} - {{ order.userAddress.state }}
                    <br />
                    {{ order.userAddress.postal_code }}
                  </p>
                  <a
                    :href="`https://www.google.com/maps/place/${order.userAddress.address}, ${order.userAddress.number} ${order.userAddress.neighborhood}, ${order.userAddress.city} - ${order.userAddress.state}`"
                    target="_blank"
                    class="small d-print-none"
                    >Ver no mapa</a
                  >
                </div>
              </li>
              <li class="list-separated-item pb-2">
                <div v-if="order.user.annotations">
                  <p class="text-muted-dark">
                    <strong>Anotações:</strong>
                    <span class="pre-formatted">{{
                      order.user.annotations
                    }}</span>
                  </p>
                </div>
                <p class="text-muted-dark">
                  <strong>Último pedido:</strong>
                  {{ userStats.last_date | datetime }}
                  <br />
                  <strong>Qtde. pedidos:</strong>
                  {{ userStats.count }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <order-status-modal
      ref="order-cancel-modal"
      :order="order"
      :newStatus="cancelStatus"
      @change="changedStatus"
    />
    <order-history-modal ref="order-history-modal" :order="order" />
    <order-delivery-type-modal
      ref="order-delivery-type-modal"
      :order="order"
      @change="changedStatus"
    />
    <order-payment-type-modal
      ref="order-payment-type-modal"
      :order="order"
      @change="changedStatus"
    />
    <order-date-modal
      ref="order-date-modal"
      :order="order"
      @change="changedStatus"
    />
    <order-discount-addition-modal
      ref="order-discount-addition-modal"
      :order="order"
      @change="changedStatus"
    />
    <order-annotations-modal
      ref="order-annotations-modal"
      :order="order"
      @change="changedStatus"
    />
    <order-set-paid-modal
      ref="order-set-paid"
      :order="order"
      @change="changedStatus"
    />
    <order-edit-items
      ref="order-edit-items"
      :order="order"
      @change="changedStatus"
    />
  </div>
</template>

<script>
import orderClient from "@/clients/order";
import customerClient from "@/clients/customer";
import orderStatusTag from "@/components/order-status-tag";
import orderPaidTag from "@/components/order-paid-tag";
import orderNextStatusBtn from "@/components/order-next-status-btn";
import orderStatusModal from "@/components/order-status-modal";
import orderHistoryModal from "@/components/order-history-modal";
import orderDeliveryTypeModal from "@/components/order-delivery-type-modal";
import orderPaymentTypeModal from "@/components/order-payment-type-modal";
import orderDateModal from "@/components/order-date-modal";
import orderDiscountAdditionModal from "@/components/order-discount-addition-modal";
import orderAnnotationsModal from "@/components/order-annotations-modal";
import orderSetPaidModal from "@/components/order-set-paid-modal";
import orderEditItems from "@/components/order-edit-items";
import orderStatusUtil from "@/utils/order-status";
import { VBTooltip } from "bootstrap-vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    orderStatusTag,
    orderNextStatusBtn,
    orderStatusModal,
    orderHistoryModal,
    orderDeliveryTypeModal,
    orderPaymentTypeModal,
    orderDiscountAdditionModal,
    orderAnnotationsModal,
    orderSetPaidModal,
    orderEditItems,
    orderDateModal,
    orderPaidTag,
  },
  computed: {
    ableToEdit() {
      return (
        this.order &&
        this.order.status !== orderStatusUtil.delivered.key &&
        this.order.status !== orderStatusUtil.canceled.key
      );
    },
  },
  data: function () {
    return {
      isLoading: true,
      cancelStatus: orderStatusUtil.canceled.key,
      order: {},
      userStats: {},
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.order = await orderClient.getOne(this.$route.params.id);
      this.userStats = await customerClient.getOrderStats(this.order.user_id);
      this.isLoading = false;
    },
    changedStatus() {
      this.getData();
    },
    cancelOrder() {
      this.$refs["order-cancel-modal"].open();
    },
    openHistory() {
      this.$refs["order-history-modal"].open();
    },
    editDeliveryType() {
      this.$refs["order-delivery-type-modal"].open();
    },
    editPaymentType() {
      this.$refs["order-payment-type-modal"].open();
    },
    editDeliveryDate() {
      this.$refs["order-date-modal"].open();
    },
    editDiscountAddition() {
      this.$refs["order-discount-addition-modal"].open();
    },
    editAnnotations() {
      this.$refs["order-annotations-modal"].open();
    },
    setPaid() {
      this.$refs["order-set-paid"].open();
    },
    editItems() {
      this.$refs["order-edit-items"].open();
    },
    async setUnpaid() {
      await orderClient.update(this.order.id, {
        paid: false,
        paid_amount: 0,
      });
      this.$toast.success("Pedido salvo.");
      this.changedStatus();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
.order-header {
  display: flex;
}
.order-header .tag-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-header .order-options {
  margin-left: auto;
  display: flex;
}
.order-options .btn-list {
  display: flex;
}
.order-options .btn-list .dropdown {
  align-items: center;
  justify-content: center;
  display: flex;
}
.card-title-small {
  display: inline !important;
}
.pre-formatted {
  white-space: pre;
}
</style>
