import defaultClient from './default';

export default {
  getOrderStats(id) {
    return defaultClient.get(`customers/${id}/orders-stats`);
  },
  getAll(query) {
    return defaultClient.get(`customers`, query);
  },
  getOne(id) {
    return defaultClient.get(`customers/${id}`);
  },
  create(data) {
    return defaultClient.post(`customers`, data);
  },
  update(id, data) {
    return defaultClient.put(`customers/${id}`, data);
  }
};