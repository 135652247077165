<template>
  <button type="submit" class="btn btn-primary" :class="{'btn-loading': isLoading}" :disabled="isLoading">{{label}}</button>
</template>

<script>
export default {
  name: 'form-save-btn',
  props: ['label', 'isLoading']
};
</script>

<style>
</style>
