<template>
  <b-modal :id="id" title="Alterar data de entrega" header-close-content @show="reset">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-label">Data de entrega</label>
          <date-picker
            v-model="deliveryDate"
            format="DD/MM/YYYY"
            input-class="form-control"
            :input-attr="{ required: true }"
          ></date-picker>
        </div>
      </div>
      <div class="col-md-6">
        <form-remote-select
          label="Período"
          data="delivery_periods"
          :value="deliveryPeriod"
          v-model="deliveryPeriod"
          :required="true"
        />
      </div>
    </div>
    <template v-slot:modal-footer="{ cancel }">
      <button
        type="button"
        class="btn btn-secondary"
        :disabled="isLoading"
        @click="cancel()"
      >Cancelar</button>
      <button
        type="button"
        class="btn btn-primary"
        :class="{'btn-loading': isLoading}"
        @click="save()"
      >Salvar</button>
    </template>
  </b-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pt-br";
import "vue2-datepicker/index.css";
import formRemoteSelect from "@/components/form-remote-select";
import orderClient from "@/clients/order";
import { VBModal } from "bootstrap-vue";
import moment from "moment";

export default {
  name: "order-date-modal",
  props: ["order"],
  directives: {
    "b-modal": VBModal,
  },
  components: {
    DatePicker,
    formRemoteSelect,
  },
  data: function () {
    return {
      isLoading: false,
      deliveryDate: null,
      deliveryPeriod: null,
    };
  },
  computed: {
    id() {
      return `order-date-modal`;
    },
  },
  methods: {
    open() {
      this.$bvModal.show(this.id);
    },
    close() {
      this.$bvModal.hide(this.id);
    },
    reset() {
      this.isLoading = false;
      this.deliveryDate = this.order.delivery_date
        ? moment(this.order.delivery_date).toDate()
        : null;
      this.deliveryPeriod = this.order.deliveryPeriod
        ? this.order.deliveryPeriod.id
        : null;
    },
    async save() {
      this.isLoading = true;
      // TODO: handle error
      await orderClient.update(this.order.id, {
        delivery_date: this.deliveryDate,
        delivery_period_id: this.deliveryPeriod,
      });
      this.$toast.success("Data de entrega alterada.");
      this.$emit("change");
      this.close();
    },
  },
};
</script>

<style>
</style>
