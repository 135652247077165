<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Produtos</h3>
        </div>
        <data-table resource="products" :columns="tableColumns" :topButtons="tableTopButtons" />
      </div>
    </div>
  </div>
</template>

<script>
import dataTable from "@/components/datatable";

export default {
  components: {
    dataTable
  },
  data: function() {
    return {
      tableColumns: [
        { column: "name", name: "Nome", route: "products-edit" },
        { column: "category.name", name: "Categoria" },
        { column: "price", name: "Valor", format: "money" },
        { column: "published", name: "Publicado", format: "yesno" }
      ],
      tableTopButtons: [
        {
          label: "Estoque",
          type: "secondary",
          route: "products-stock"
        }
      ]
    };
  }
};
</script>

<style>
</style>
