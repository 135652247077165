<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Perfil</h3>
        </div>
        <center v-if="isLoading">Carregando...</center>
        <form @submit.prevent="save" v-else>
          <div class="card-body">
            <form-input label="Nome" v-model="user.name" :required="true" :autofocus="true" />
            <form-input label="E-mail" v-model="user.email" type="email" :required="true" />
            <form-masked-input
              label="Celular"
              v-model="user.phone"
              mask="(##) #####-####"
              :required="true"
            />
            <form-input
              label="Nova senha"
              type="password"
              helpText="Apenas preencha se deseja alterar sua senha."
              v-model="user.password"
            />
          </div>
          <div class="card-footer text-right">
            <div class="d-flex">
              <form-save-btn class="ml-auto" label="Salvar" :isLoading="isSaving" />
            </div>
          </div>
        </form>
      </div>
      <small>* campos obrigatórios</small>
    </div>
  </div>
</template>

<script>
import formInput from "@/components/form-input";
import formMaskedInput from "@/components/form-masked-input";
import formSaveBtn from "@/components/form-save-btn";
import userClient from "@/clients/user";
import authService from "@/services/auth";

export default {
  components: {
    formInput,
    formMaskedInput,
    formSaveBtn,
  },
  data: function () {
    return {
      isLoading: true,
      isSaving: false,
      user: {},
    };
  },
  methods: {
    async save() {
      this.isSaving = true;
      await userClient.update(this.user.id, this.user);
      this.$toast.success("Perfil salvo.");
      this.isSaving = false;
      // TODO: handle error
    },
    async getData() {
      const userId = authService.getUser().id;
      this.user = await userClient.getOne(userId);
      this.isLoading = false;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style>
</style>
